import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import Quagga from '@ericblade/quagga2';
import {DECODER_CONFIG, DECODER_LIVE_CONFIG} from '../../config/decoder-config';

@Injectable()
export class BarcodeDecoderService {

  private static setLiveStreamConfig() {
    DECODER_LIVE_CONFIG.inputStream = {
      type: 'LiveStream',
      constraints: {
        size: 2448, // restricts input-size in width (long side)
        facingMode: 'environment',
      },
      // area: {
      //   top: '25%',
      //   right: '10%',
      //   left: '10%',
      //   bottom: '25%',
      // },
    };
    return DECODER_LIVE_CONFIG;
  }

  constructor(private logger: NGXLogger) {
  }

  onDecodeSingle(src) {
    DECODER_CONFIG.src = src;
    // Promisify DecodeSingle method from Quagga
    return new Promise((resolve, reject) => {
      void Quagga.decodeSingle(DECODER_CONFIG, (result) => {
        if (!result || typeof result.codeResult === 'undefined') {
          reject('File Cannot be Decode, Please Try a Valid Barcode;');
        }
        resolve(result.codeResult.code);
      });
    });
  }

  onLiveStreamInit(success?: (activeTrack: MediaStreamTrack) => any, error?: (error: any) => any) {
    const state = BarcodeDecoderService.setLiveStreamConfig();
    void Quagga.init(state, (err) => {
      if (err) {
        this.logger.error('Error initializing Quagga: ' + JSON.stringify(err));
        if (error) {
          error(err);
        }
        return;
      }
      // Moved activeTrack here instead of in success(Quagga.CameraAccess.getActiveTrack())
      // Not sure if it is possible for the active track to be null after Quagga.start() is called
      // See https://jira.supremesteel.com/browse/BARCODE-116
      const activeTrack = Quagga.CameraAccess.getActiveTrack();

      this.checkCapabilities();
      Quagga.start();

      success(activeTrack);
    });
  }

  checkCapabilities() {
    const track = Quagga.CameraAccess.getActiveTrack();
    if (track != null) {
      let capabilities: any = {};
      if (typeof track.getCapabilities === 'function') {
        capabilities = track.getCapabilities();
      }
      // if (capabilities.zoom != null) {
      //   track.applyConstraints({advanced: [{zoom: capabilities.zoom.max}]});
      // }
      // track.applyConstraints({advanced: [{torch: true}]});
    }
  }

  getActiveTrack(): MediaStreamTrack | any | null {
    return Quagga.CameraAccess.getActiveTrack();
  }

  onProcessed(result: any) {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0,
          parseInt(drawingCanvas.getAttribute('width'), 10),
          parseInt(drawingCanvas.getAttribute('height'), 10));
        result.boxes.filter((box) => box !== result.box).forEach((box) => {
          Quagga.ImageDebug.drawPath(box, {
            x: 0,
            y: 1,
          }, drawingCtx, {
            color: 'green',
            lineWidth: 10,
          });
        });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, {
          x: 0,
          y: 1,
        }, drawingCtx, {
          color: '#00F',
          lineWidth: 10,
        });
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(result.line, {
          x: 'x',
          y: 'y',
        }, drawingCtx, {
          color: 'red',
          lineWidth: 15,
        });
      }

    } else {
      drawingCtx.clearRect(0, 0,
        parseInt(drawingCanvas.getAttribute('width'), 10),
        parseInt(drawingCanvas.getAttribute('height'), 10));
    }
  }

  onDecodeProcessed() {
    Quagga.onProcessed(this.onProcessed);
  }

  onDecodeDetected() {
    // Promisify OnDetected method from Quagga
    return new Promise((resolve, reject) => {
      Quagga.onDetected((result) => {

        if (!result || typeof result.codeResult === 'undefined') {
          reject('Cannot be Detected, Please Try again!');
        }
        resolve(result.codeResult.code);
      });
    });
  }

  onDecodeStop() {
    void Quagga.stop();
    Quagga.offProcessed(this.onProcessed);
    Quagga.offDetected();
    this.logger.info('Camera Stopped Working!');
  }

  // onPlaySound(): Promise<any> {
  //   BarcodeSounds.ScanBeep.load();
  //   return BarcodeSounds.ScanBeep.play();
  // }

}
