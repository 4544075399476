import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ShippingDetail, ShippingDetailService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class ShippingDetailGridService extends TableService<ShippingDetail> {
  constructor(http: HttpClient, private clientService: ShippingDetailService) {
    super(http, 'ShippingDetail', '/api/v1/odata/');
    this.settingsName = 'ShippingHistory';
  }

  getIdUrlPart(data: any | ShippingDetail): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | ShippingDetail): Observable<any> {
    return EMPTY;
  }

  update(data: any | ShippingDetail): Observable<any> {
/*
    data = this.clearExtendedProperties(data);
    return this.clientService.shippingDetailPatch(data.id, this.cleanupItem(data));
*/
    return EMPTY;
  }

  add(data: any | ShippingDetail): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | ShippingDetail): ShippingDetail {
    return data;
  }

  protected mapToObject(responseData: any): ShippingDetail {
    return responseData as ShippingDetail;
  }

}
