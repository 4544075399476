import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {SystemOption, SystemOptionService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class SystemOptionGridService extends TableService<SystemOption> {
  constructor(http: HttpClient, private clientService: SystemOptionService) {
    super(http, 'SystemOption', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | SystemOption): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | SystemOption): Observable<any> {
    // TODO
    return EMPTY;
  }

  update(data: any | SystemOption): Observable<any> {
    // System options that contain boolean "true" or "false" strings should be persisted as such (as a string)
    // The below case will force convert boolean true and false into "true" and "false"
    if (data.value != null) {
      data.value = data.value.toString();
    }
    return this.clientService.systemOptionPatch(data.id, this.cleanupItem(data));
    // return EMPTY;
  }

  add(data: any | SystemOption): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any | SystemOption): SystemOption {
    if (responseData != null && responseData.valueType === 'checkbox') {
      responseData.value = Boolean(JSON.parse(responseData.value));
    }
    return responseData as SystemOption;
  }
}
