/**
 * Based on jQuery code from https://github.com/kabachello/jQuery-Scanner-Detection
 * Features needed ported by David Yee 2019-11-05
 *
 * @author David Yee
 */

import {Injectable, OnDestroy} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BarcodeSounds} from './barcode-sounds';
import {ScannerDetectionOptions} from './scanner-detection-options';
import {ScannerDetectionResults} from './scanner-detection-results';
import Timeout = NodeJS.Timeout;

@Injectable()
export class ScannerDetectionService implements OnDestroy {

  public results = new ScannerDetectionResults();

  private callIsScanner = false;
  private testTimer: Timeout = null;
  private stringWriting = '';
  private firstCharTime: number = null;
  private lastCharTime: number = null;
  private scanButtonCounter = 0;

  private destroy$ = new Subject<boolean>();

  constructor(private options: ScannerDetectionOptions) {
    fromEvent(options.target, 'keypress')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: KeyboardEvent) => this.scannerDetection(this, e));
    this.results
      .onComplete
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => {
        // BarcodeSounds.ScanBeep.load();
        BarcodeSounds.onPlayBeepSound().then();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private scannerDetection(self: ScannerDetectionService, event: KeyboardEvent) {

    function initScannerDetection() {
      self.firstCharTime = 0;
      self.stringWriting = '';
      self.scanButtonCounter = 0;
    }

    function scannerDetectionTest(s: string = null) {
      // If string is given, test it
      if (s) {
        self.firstCharTime = self.lastCharTime = 0;
        self.stringWriting = s;
      }

      if (!self.scanButtonCounter) {
        self.scanButtonCounter = 1;
      }

      // If all condition are good (length, time...), call the callback and re-initialize the plugin for next scanning
      // Else, just re-initialize
      if (self.stringWriting.length >= self.options.minLength && self.lastCharTime - self.firstCharTime < self.stringWriting.length * self.options.avgTimeByChar) {
        // Uncomment the below to use long pressed feature
        // This feature is not used in our implementation/hardware
        //
        // if (self.results.onScanButtonLongPressed && self.scanButtonCounter > self.options.scanButtonLongPressThreshold)
        //   self.results.onScanButtonLongPressed.next({
        //     code: self.stringWriting,
        //     qty: self.scanButtonCounter,
        //   });
        // else
        if (self.results.onComplete) {
          self.results.onComplete.next({
            code: self.stringWriting,
            qty: self.scanButtonCounter,
          });
        }

        initScannerDetection();
        return true;
      } else {
        if (self.results.onError) {
          self.results.onError.next(self.stringWriting);
        }
        initScannerDetection();
        return false;
      }
    }

    if (self.firstCharTime && self.options.endChar.indexOf(event.which) !== -1) {
      event.preventDefault();
      event.stopImmediatePropagation();
      self.callIsScanner = true;
    } else if (!self.firstCharTime && self.options.startChar.indexOf(event.which) !== -1) {
      event.preventDefault();
      event.stopImmediatePropagation();
      self.callIsScanner = false;
    } else {
      self.stringWriting += String.fromCharCode(event.which);
      self.callIsScanner = false;
    }

    if (!self.firstCharTime) {
      self.firstCharTime = Date.now();
    }
    self.lastCharTime = Date.now();

    if (self.testTimer) {
      clearTimeout(self.testTimer);
    }
    if (self.callIsScanner) {
      scannerDetectionTest();
      self.testTimer = null;
    } else {
      self.testTimer = setTimeout(scannerDetectionTest, self.options.timeBeforeScanTest);
    }

    if (self.results.onReceive) {
      self.results.onReceive.next(event.key);
    }
  }
}
