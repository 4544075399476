import {Injectable} from "@angular/core";
import {NGXLogger} from "ngx-logger";
import {Router} from "@angular/router";
import {Employee, Timesheet} from "../../../production-progressing-api";
import {EmployeeService} from "./employee.service";
import {TaskBuilderService} from "../../pages/shared/task-builder.service";

@Injectable()
export class CustomPathBuilderService {

  constructor(
    private logger: NGXLogger,
    private employeeService: EmployeeService,
    private taskBuilderService: TaskBuilderService,
    private router: Router) {}

  handleCutProcessingRoute(employee: Employee, timesheet: Timesheet) {
    const taskTypeGroupId: number = timesheet?.taskType?.taskTypeGroup?.id;
    const contractId: number = timesheet?.contractId;
    const groupId: number = timesheet?.transmittalId;
    const stationId: number = timesheet?.stationId;

    /// Cut Processing specific pathing: build a custom path if the taskType is Cut Processing related
    if (taskTypeGroupId === 75) {
      this.taskBuilderService.contract$.next(timesheet?.contract);
      this.taskBuilderService.group$.next(timesheet?.transmittal);
      this.taskBuilderService.station$.next(timesheet?.station);
      this.taskBuilderService.ewo$.next(null);

      this.employeeService.publishEmployee(employee, false);
      void this.router.navigate(['/pages/progress/employee/new-task/contract/cut-processing/contract/'+contractId+'/group/'+groupId+'/station/'+stationId+'/ewo/null/preview']);
    } else {
      this.employeeService.publishEmployee(employee, true);
    }
  }

  handleCutProcessingConcurrencyRoute(employee: Employee) {
    this.employeeService.publishEmployee(employee, false);
    void this.router.navigate(['/pages/progress/employee/task/']);
  }
}
