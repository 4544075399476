import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {
  Employee,
  EmployeeService as EmployeeApiService,
  ODataValueOfIEnumerableOfEmployee,
  Timesheet,
} from '../../../production-progressing-api';

@Injectable()
export class EmployeeService {

  static readonly StorageKey = 'employee';

  static GetNextOpenTimesheet(employee: Employee): Timesheet | undefined {
    if (employee != null) {
      return employee.timesheets.find((e) => e.endTime == null);
    }
    return undefined;
  }

  protected employee: BehaviorSubject<Employee> = new BehaviorSubject(null);

  /// Safely request a stop timesheet so that listeners can intercept it before
  /// stopping a timesheet immediately
  ///
  /// Subject contains a timesheet ID
  protected stopTimesheetRequest = new Subject<number>();

  /// Request stop of a timesheet immediately (ready to stop)
  /// Subject contains a timesheet ID
  protected stopTimesheetNow = new Subject<number>();

  protected showHideEndTimesheetButton = new Subject<boolean>();

  private refreshingEmployeeSub: Subscription;

  private defaultEmployeeExpand: string = 'workShift,timesheets(' +
    '$expand=asCutProgresses,taskType($expand=tasktypegroup($select=name,id);$select=id,name,isoverhead,tasktypegroup)' +
    ',stationGroup,station($expand=stationLocation,stationGroup),contract,transmittal($expand=contract);' +
    '$filter=endTime eq null;' +
    '$top=100;' +
    '$orderby=startTime desc;' +
    ')';

  constructor(
    private logger: NGXLogger,
    private router: Router,
    private employeeService: EmployeeApiService) {
    if (sessionStorage.getItem(EmployeeService.StorageKey)) {
      const employee: Employee = JSON.parse(sessionStorage.getItem(EmployeeService.StorageKey));

      this.publishEmployee(employee);

      // refresh the cached copy
      this.refreshEmployeeAndPublish(employee);
    }
  }

  refreshEmployeeAndPublish(employee: Employee = null, navigate: boolean = false) {
    if (employee != null && employee.id != null) {
      this.refreshingEmployeeSub = this.getEmployeeById(employee.id)
        .subscribe((e) => this.publishEmployee(e, navigate));
    }
  }

  getActiveEmployeesByNumber(number: string): Observable<ODataValueOfIEnumerableOfEmployee> {
    number = number.replace(/'/g, '\'\'');
    return this.employeeService.employeeGetEmployeeByEmployeeNumber(
      `${number}`,
      undefined,
      this.defaultEmployeeExpand,
      'deletionDate eq null and isActive eq true',
      'employeeNumber');
  }

  getEmployeeById(id: number): Observable<Employee> {
    return this.employeeService.employeeGetById(
      id,
      undefined,
      this.defaultEmployeeExpand,
      'body');
  }

  publishEmployee(user: Employee, navigate: boolean = false) {
    // Cancel cache refresh if we're changing the employee before the cache is refreshed
    if (this.refreshingEmployeeSub) {
      this.refreshingEmployeeSub.unsubscribe();
    }

    this.employee.next(user);
    sessionStorage.setItem(EmployeeService.StorageKey, JSON.stringify(this.employee.value));
    // this.logger.trace('Persisting employee number to session storage: ' + JSON.stringify(this.employee.value));

    if (navigate) {
      if (user != null) {
        // this.logger.debug('Navigating to the proper screen...');
        const taskGroup = sessionStorage.getItem('TaskTypeGroup');

        if (taskGroup !== '' && taskGroup != null) {
          void this.router.navigate(['pages/progress/employee/product-new-task/', taskGroup]);
        } else {
          if (user.timesheets == null || user.timesheets.length === 0 || (user.timesheets[0] != null && user.timesheets[0].endTime != null)) {
            void this.router.navigate(['pages/progress/employee/']);
          } else if (user.timesheets[0] != null && user.timesheets[0].endTime == null) {
            void this.router.navigate(['pages/progress/employee/task']);
          }
        }
      }
    }
  }

  publishEmployeeCutProcessing(user: Employee, navigate: boolean = false) {
    // Cancel cache refresh if we're changing the employee before the cache is refreshed
    if (this.refreshingEmployeeSub) {
      this.refreshingEmployeeSub.unsubscribe();
    }

    this.employee.next(user);
    sessionStorage.setItem(EmployeeService.StorageKey, JSON.stringify(this.employee.value));
    // this.logger.trace('Persisting employee number to session storage: ' + JSON.stringify(this.employee.value));

    if (navigate) {
      if (user != null) {
        if (user.timesheets == null || user.timesheets.length === 0 || (user.timesheets[0] != null && user.timesheets[0].endTime != null)) {
          void this.router.navigate(['pages/progress/employee/']);
        } else if (user.timesheets[0] != null && user.timesheets[0].endTime == null) {
          void this.router.navigate(['pages/progress/employee/task']);
        }
      }
    }
  }

  onEmployeeChange(): Observable<Employee> {
    return this.employee;
  }

  /// Nicely request to end a timesheet, allowing any interceptors a chance to respond first.
  requestStopTimesheet(timesheetId: number) {
    this.stopTimesheetRequest.next(timesheetId);
  }

  /// Formally close a timesheet immediately without any interception
  forceStopTimesheet(timesheetId: number) {
    this.stopTimesheetNow.next(timesheetId);
  }

  onStopTimesheetRequested(): Observable<number> {
    return this.stopTimesheetRequest;
  }

  onStopTimesheetNow(): Observable<number> {
    return this.stopTimesheetNow;
  }

  showTimesheetEndButton() {
    this.showHideEndTimesheetButton.next(true);
  }

  hideTimesheetEndButton() {
    this.showHideEndTimesheetButton.next(false);
  }

  onShowHideTimesheetEndButtonChange(): Observable<boolean> {
    return this.showHideEndTimesheetButton;
  }

}
