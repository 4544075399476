import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Question, QuestionService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';
import {formatISO} from "date-fns";

@Injectable()
export class QuestionGridService extends TableService<Question> {
  constructor(http: HttpClient, private clientService: QuestionService) {
    super(http, 'Question', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Question): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | Question): Observable<any> {
    // @ts-ignore
    return this.clientService.questionPatch(data.id, {deletionDate: formatISO(new Date())});
  }

  update(data: any | Question): Observable<any> {
    return this.clientService.questionPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | Question): Observable<any> {
    return this.clientService.questionPost(this.cleanupItem(data));
  }

  protected mapToObject(responseData: any): Question {
    return responseData as Question;
  }

}
