import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Crew, Station, StationService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class StationListGridService extends TableService<Crew> {
  constructor(http: HttpClient, private clientService: StationService) {
    super(http, 'Station', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Station): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | Station): Observable<any> {
    return this.clientService.stationDelete(data.id);
  }

  update(data: any | Station): Observable<any> {
    data = this.clearExtendedProperties(data);
    return this.clientService.stationPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | Station): Observable<any> {
    data = this.clearExtendedProperties(data);
    return this.clientService.stationPost(this.cleanupItem(data));
  }

  clearExtendedProperties(data: any | Station): Station {
    delete data.isAssigned;
    return data;
  }

  protected mapToObject(responseData: any): Station {
    responseData.isAssigned = responseData.stationGroup != null;
    return responseData as Station;
  }
}
