import {Injectable} from '@angular/core';

@Injectable()
export class ScannerDetectionOptions {
  // Average time (ms) between 2 chars. Used to do difference between keyboard typing and scanning
  avgTimeByChar = 40;
  /// Chars to remove and means end of scanning
  endChar = [9, 13, 17, 74];
  /// do not handle scans if the currently focused element matches this selector
  /// TODO: Not used
  ignoreIfFocusOn = false;
  minLength = 5;
  /// Prevent default action on keypress event
  preventDefault = false;
  /// Key code of the scanner hardware button (if the scanner button a acts as a key itself)
  /// TODO: Not used
  scanButtonKeyCode = 0;
  /// How many times the hardware button should issue a pressed event
  /// TODO: Not used
  scanButtonLongPressThreshold = 0;
  /// Chars to remove and means start of scanning
  startChar = [2];
  /// Stop immediate propagation on keypress event
  stopPropagation = false;
  /// Wait duration (ms) after keypress event to check if scanning is finished
  timeBeforeScanTest = 200;
  /// The element to apply the listener to
  target = window;
}
