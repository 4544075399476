import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ErrorResponse, Shipping, ShippingService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class ShippingLoadGridService extends TableService<Shipping> {
  constructor(http: HttpClient, private clientService: ShippingService) {
    super(http, 'Shipping', '/api/v1/odata/');
  }

  queryStems(state: any, params = {}, jobName: string = null): void {
    this.fetch(this.tableName
      + `/GetStemsShipping(jobName='${jobName}')`
      , state, null, params)
      .subscribe({
        next: (x) => {
          super.next(x);
        }, error: (_: ErrorResponse) => {
          super.next({data: [], total: 0});
        }
      });
  }

  getIdUrlPart(data: any | Shipping): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | Shipping): Observable<any> {
    return EMPTY;
  }

  update(data: any | Shipping): Observable<any> {
    data = this.clearExtendedProperties(data);
    return this.clientService.shippingPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | Shipping): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | Shipping): Shipping {
    return data;
  }

  protected mapToObject(responseData: any): Shipping {
    return responseData as Shipping;
  }

}
