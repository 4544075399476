import {NgxLoggerLevel} from 'ngx-logger';
import {LogLevel} from '@azure/msal-browser';

export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  environment: '',
  loggingLevel: NgxLoggerLevel.WARN,
  msalLoggingLevel: LogLevel.Warning,
  authority: 'https://login.microsoftonline.com/ddcdd9fc-eafc-4955-bdd4-1463255c7dc8/',
  azureClientId: '052dbe2c-0dd1-4dba-a934-e9f37f674612',
  protectedResourceMap: [
    ['https://graph.microsoft.com/v1.0', ['User.Read', 'User.Read.All', 'User.ReadBasic.All', 'User.ReadWrite']],
    ['/api/v1/Environment', null], // unprotected route have null scope
    ['/api/*', ['api://052dbe2c-0dd1-4dba-a934-e9f37f674612/Data.Read.All', 'api://052dbe2c-0dd1-4dba-a934-e9f37f674612/Data.Write.All']],
    // ... other scopes
  ],
  stemsBaseUrl: window.location.origin + '/_stems',
  docuwerxBaseUrl: window.location.origin + '/_docuwerx',
  companyLink: '',
  apiBaseUrl: window.location.origin,
  appInsights: {
    instrumentationKey: '3f4896ba-7d9a-4571-834d-1ecc99b45e57',
  },
};
