import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {StatusSummary} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';
import {State} from '@progress/kendo-data-query';
import 'symbol-observable';
import { TransmittalService } from '../../../../production-progressing-api';

@Injectable()
export class StatusSummaryGridService extends TableService<StatusSummary> {

  transmittalSubscription: any;
  stationSubscription: any; 

  constructor(http: HttpClient, public transmittalService: TransmittalService) {
    super(http, 'StatusSummary', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | StatusSummary): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  setTableName(isGroup: boolean) {
    this.settingsName = isGroup ? 'StatusSummaryTransmittal' : 'StatusSummaryGroup';
  }

  queryTransmittal(state: any | State, params = {}): void {
    //handles scenarios where paging is taking place 
    if (this.transmittalSubscription ) {
      this.transmittalSubscription.unsubscribe();
    } 
    //handles scenarios a quick switch is executed between types  
    if (this.stationSubscription ) {
      this.stationSubscription.unsubscribe();
    }
    this.transmittalSubscription = this.fetch(`Transmittal/GetOpenApproved_LatestStatusByTask()`, state, null, params)
      .subscribe(x => {super.next(x)});   
  }

  queryStationGroup(state: any | State, params = {}): void {
    if (this.stationSubscription ) {
      this.stationSubscription.unsubscribe();
    } 
    if (this.transmittalSubscription ) {
      this.transmittalSubscription.unsubscribe();
    } 
    this.stationSubscription = this.fetch(`StationGroup/GetOpenStationGroups_LatestStatusByTask()`, state, null, params)
      .subscribe(x => super.next(x));
  }

  delete(data: any | StatusSummary): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | StatusSummary): Observable<any> {
    // return this.client.transmittalPatch(data.id, this.cleanupItem(data));
    return EMPTY;
  }

  add(data: any | StatusSummary): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): StatusSummary {
    return responseData as StatusSummary;
  }
}
