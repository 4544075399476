import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ProductTimesheet} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class ProductTimesheetGridService extends TableService<ProductTimesheet> {
  constructor(http: HttpClient) {
    super(http, 'ProductTimesheet', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | ProductTimesheet): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | ProductTimesheet): Observable<any> {
    return EMPTY;
  }

  update(data: any | ProductTimesheet): Observable<any> {
    return EMPTY;
  }

  add(data: any | ProductTimesheet): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | ProductTimesheet): ProductTimesheet {
    return data;
  }

  protected mapToObject(responseData: any): ProductTimesheet {
    return responseData as ProductTimesheet;
  }

}
