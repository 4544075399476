import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {TimesheetHelper} from '../../utils/timesheet-helper';
import {TableService} from '@framewerx/kendo-angular-grid';
import {ErrorResponse, Timesheet, TimesheetService} from '../../../../production-progressing-api';

@Injectable()
export class EmployeeTimesheetsGridService extends TableService<Timesheet> {
  constructor(http: HttpClient, private clientService: TimesheetService) {
    super(http, 'Timesheet', '/api/v1/odata/');
  }

  changeApproval(data: any | Timesheet, workDate: Date, approve: boolean): Observable<any> {
    return this.clientService.timesheetChangeApproved({
      isApprove: approve,
      workDate: workDate.toISOString(),
      employeeId: data.employeeId,
      timesheetId: data.id
    });
  }

  getEmployeeTimesheets(employeeId: number, workDate: Date, state: any, params = {}): void {
    const newParams = Object.assign({}, params);
    // newParams['asOfDate'] = asOfDate.toISOString();
    this.fetch(this.tableName
      + `/GetEmployeeTimesheets(employeeId=${employeeId != null ? employeeId : 0},workDate=${encodeURIComponent(String(workDate.toISOString()))})`
      , state, null, newParams)
      .subscribe({
        next: (x) => {
          super.next(x);
        }, error: (_: ErrorResponse) => {
          super.next({data: [], total: 0});
        }
      });
  }

  getIdUrlPart(data: any | Timesheet): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | Timesheet): Observable<any> {
    return EMPTY;
  }

  update(data: any | Timesheet): Observable<any> {
    data = this.clearExtendedProperties(data);
    return this.clientService.timesheetPatch(data.id, {isApproved: data.isApproved});
  }

  add(data: any | Timesheet): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | Timesheet): Timesheet {
    delete data.duration;
    delete data.products;
    return data;
  }

  protected mapToObject(responseData: any): Timesheet {
    responseData.duration = TimesheetHelper.DisplayDuration(responseData);
    responseData.products = responseData['productTimesheets@odata.count'];
    delete responseData['productTimesheets@odata.count'];
    return responseData as Timesheet;
  }

}
