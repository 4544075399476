import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  StationLocation, StationLocationService,
} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class StationLocationGridService extends TableService<StationLocation> {
  constructor(http: HttpClient, private clientService: StationLocationService) {
    super(http, 'StationLocation', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | StationLocation): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | StationLocation): Observable<any> {
    return this.clientService.stationLocationPatch(data.id, {name: data.name, deletionDate: new Date()});
  }

  update(data: any | StationLocation): Observable<any> {
    return this.clientService.stationLocationPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | StationLocation): Observable<any> {
    return this.clientService.stationLocationPost(this.cleanupItem(data));
  }

  protected mapToObject(responseData: any): StationLocation {
    return responseData as StationLocation;
  }
}
