import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ProductStatusSummary} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';
import {State} from '@progress/kendo-data-query';

@Injectable()
export class ProductStatusSummaryGridService extends TableService<ProductStatusSummary> {

  transmittalSubscription: any;
  stationSubscription: any; 

  constructor(http: HttpClient) {
    super(http, 'ProductStatus', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | ProductStatusSummary): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  queryTransmittal(transmittalId, state: any | State, params = {}): void {

    if (this.transmittalSubscription) {
      this.transmittalSubscription.unsubscribe();
    }
    this.transmittalSubscription = this.fetch(`Product/GetProductLatestStatusByTaskForTransmittal(transmittalId=${transmittalId})`, state, null, params)
      .subscribe(x => super.next(x));
  }

  queryStationGroup(stationGroupId, state: any | State, params = {}): void {

    if (this.stationSubscription) {
      this.stationSubscription.unsubscribe();
    }
    this.stationSubscription = this.fetch(`Product/GetProductLatestStatusByTaskAtStationGroup(stationGroupId=${stationGroupId})`, state, null, params)
      .subscribe(x => super.next(x));
  }

  delete(data: any | ProductStatusSummary): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | ProductStatusSummary): Observable<any> {
    // return this.client.transmittalPatch(data.id, this.cleanupItem(data));
    return EMPTY;
  }

  add(data: any | ProductStatusSummary): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): ProductStatusSummary {
    return responseData as ProductStatusSummary;
  }
}
