import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {EmployeeService} from '../../../production-progressing-api';

@Injectable()
export class EmployeeSelectService {

  constructor(
    private employeeService: EmployeeService
  ) {
  }

  doSearch(srchs: Observable<any>, debounceMs = 400) {
    return srchs
      .pipe(
        debounceTime(debounceMs),
        switchMap((srch) => this.rawSearch(srch)),
      );
  }

  rawSearch(srch): Observable<any> {
    return this.employeeService.employeeGet(undefined, undefined,
      `contains(name, '${srch}') or contains(employeeNumber, '${srch}')`)
      .pipe(catchError((err) => {
        return of(err);
      }));
  }

}
