import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ErrorService} from '../../pages/shared/errors/error.service';
import {DropdownListService} from './dropdown-list.service';

@Injectable()
export class StemsDropdownListService extends DropdownListService {
  BASE_ODATA_URL = `${environment.stemsBaseUrl}/api/v1/`;

  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, errorService);
  }

  query(tableName: string, state: any, params: {} = {}): void {
    super.query(tableName, state, params);
  }
}
