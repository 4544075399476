import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductLocation, ProductLocationService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class ProductLocationGridService extends TableService<ProductLocation> {
  constructor(http: HttpClient, private clientService: ProductLocationService) {
    super(http, 'ProductLocation', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | ProductLocation): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | ProductLocation): Observable<any> {
    return this.clientService.productLocationPatch(data.id, {name: data.name, deletionDate: new Date()});
  }

  update(data: any | ProductLocation): Observable<any> {
    return this.clientService.productLocationPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | ProductLocation): Observable<any> {
    return this.clientService.productLocationPost(this.cleanupItem(data));
  }

  protected mapToObject(responseData: any): ProductLocation {
    return responseData as ProductLocation;
  }
}
