import {Injectable} from '@angular/core';
import {UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {addDays, format} from 'date-fns';

@Injectable()
export class TimesheetFormValidatorService {

  constructor() {
  }

  dateValidator(): ValidatorFn {
    return (control: UntypedFormGroup): ValidationErrors | null => {
      const errors: ValidationErrors = {};

      let endTime: Date = control.get('endTime') != null ? control.get('endTime').value : null;
      const endDate: Date = control.get('endDate') != null ? control.get('endDate').value : null;
      let startTime: Date = control.get('startTime') != null ? control.get('startTime').value : null;
      const startDate: Date = control.get('startDate') != null ? control.get('startDate').value : null;
      let brassTime: Date = control.get('timestamp') != null ? control.get('timestamp').value : null;
      const brassDate: Date = control.get('timestampDate') != null ? control.get('timestampDate').value : null;
      const dayStart: Date = control.get('dayStart') != null && control.get('dayStart').value ? new Date(control.get('dayStart').value) : null;

      if (endTime != null && endDate != null) {
        endTime.setMonth(endDate.getMonth());
        endTime.setDate(endDate.getDate());
        endTime.setFullYear(endDate.getFullYear());
      } else {
        endTime = null;
      }

      if (startTime != null && startDate != null) {
        startTime.setMonth(startDate.getMonth());
        startTime.setDate(startDate.getDate());
        startTime.setFullYear(startDate.getFullYear());
      } else {
        startTime = null;
      }

      if (brassTime != null && brassDate != null) {
        brassTime.setMonth(brassDate.getMonth());
        brassTime.setDate(brassDate.getDate());
        brassTime.setFullYear(brassDate.getFullYear());
      } else {
        brassTime = null;
      }

      const prettyDateFormatIso = 'yyyy-MM-dd, hh:mm:ss a';
      const dayStartPlusOneDay = addDays(dayStart, 1);

      if (startTime != null && endTime != null && startTime > endTime) {
        errors.badTimes = {
          message: 'End Time must be later than Start Time',
        };
      }
      if (startTime != null && dayStart != null && startTime < dayStart) {
        errors.badStart = {
          message: `Start Time needs to be after '${format(dayStart, prettyDateFormatIso)}'`,
        };
      }
      if (startTime != null && dayStart != null && startTime >= dayStartPlusOneDay) {
        errors.badStart = {
          message: `Start Time needs to be before '${format(dayStartPlusOneDay, prettyDateFormatIso)}'`,
        };
      }
      if (brassTime != null && dayStart != null && brassTime < dayStart) {
        errors.badStart = {
          message: `Brass Time needs to be after '${format(dayStart, prettyDateFormatIso)}'`,
        };
      }
      if (brassTime != null && dayStart != null && brassTime >= dayStartPlusOneDay) {
        errors.badStart = {
          message: `Brass Time needs to be before '${format(dayStartPlusOneDay, prettyDateFormatIso)}'`,
        };
      }
      return Object.keys(errors).length ? errors : null;
    };
  }
}
