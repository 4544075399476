import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {
  Product, ErrorResponse, ProductLoadStatus,
} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';
// import {TableService} from './table.service';

@Injectable()
export class AssemblyProductGridService  extends TableService<Product> {
  constructor(http: HttpClient) {
    super(http, 'Product', '/api/v1/odata/');
  }

  // query() {
  //   super.next({data: [], total: 0});
  // }

  queryCandidates(productSerial: string, stationGroupId: number, state: any, params = {}): void {
    this.fetch(this.tableName
      + `/GetStationCandidateAssembly(productSerial='${productSerial}',stationGroupId=${stationGroupId})`
      , state, null, params)
    .subscribe({
      next: (x) => {
        super.next(x);
      }, error: (_: ErrorResponse) => {
        super.next({data: [], total: 0});
      }
    });
  }

  getIdUrlPart(data: any | Product): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | Product): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | Product): Observable<any> {
    return EMPTY;
  }

  add(data: any | Product): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | Product): Product {
    delete data.stationInformation;
    return data;
  }

  protected mapToObject(responseData: any): Product {
    if (responseData.stationProducts != null && responseData.stationProducts.length > 0) {
      responseData.stationInformation = {
        stationGroup: responseData.stationProducts[0].productLoadStatus === ProductLoadStatus.Loaded ||
        responseData.stationProducts[0].productLoadStatus === ProductLoadStatus.Loading ?
          responseData.stationProducts[0].stationGroup : null,
        productLoadStatus: responseData.stationProducts[0].productLoadStatus,
      };
    } else {
      responseData.stationInformation = {
        stationGroup: null,
        productLoadStatus: ProductLoadStatus.Unloaded,
      };
    }
    return responseData as Product;
  }

  protected addMetaDataToObject(responseData: any): any {
    if (responseData.products != null && responseData.products.length > 0) {
      responseData.$metadata.productCount = responseData.products.reduce((a, b) => a + b.bundleQuantity, 0);
    }
    return super.addMetaDataToObject(responseData);
  }
}
