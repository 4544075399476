<nb-card class="dialog-card nb-layout-column">
  <nb-card-header class="h3">
    <div class="d-flex flex-row gap-3">
      <div>
        {{ title }}
      </div>
      <div>
        <button class="btn btn-primary" (click)="isToolbarVisible = !isToolbarVisible">
          <ng-container *ngIf="isToolbarVisible; else showTools">
            <i class="fas fa-eye-slash fa-fw me-2"></i>
            <span class="d-none d-md-inline">Hide Drawing Tools</span>
          </ng-container>
          <ng-template #showTools>
            <i class="fas fa-eye fa-fw me-2"></i>
            <span class="d-none d-md-inline">Show Drawing Tools</span>
          </ng-template>
        </button>
      </div>
      <div class="flex-grow-1">
      </div>
      <button (click)="dismiss()"
              type="button"
              class="btn btn-danger float-end">
        <span class="fas fa-times fa-2x"></span>
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="floating-status">
      <div class="d-flex flex-grow flex-column align-items-center gap-2">
        <div *ngIf="statusMessage !== null"
             class="badge rounded-pill bg-info loading w-100">
          {{ statusMessage }}
        </div>
        <div *ngIf="errorMessage !== null"
             class="badge rounded-pill bg-danger w-100">
          {{ errorMessage }}
        </div>
      </div>
    </div>

    <framewerx-pdf-annotate *ngIf="errorMessage === null && documentStorage !== null"
                            containerId="pdf-container"
                            [enabledTools]="enabledTools"
                            [isToolbarVisible]="isToolbarVisible"
                            (pdfRendered)="setupOverlay()"
                            (objectChanged)="documentAnnotationEvent($event)"
                            [url]="documentStorage.storeName" #pdfAnnotate>
    </framewerx-pdf-annotate>
  </nb-card-body>
</nb-card>

