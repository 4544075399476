import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { Employee } from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class EmployeeSearchGridService extends TableService<Employee> {
  constructor(http: HttpClient) {
    super(http, 'Employee', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Employee): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | Employee): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | Employee): Observable<any> {
    return EMPTY;
  }

  add(data: any | Employee): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): Employee {
    return responseData as Employee;
  }
}
