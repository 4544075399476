import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {Product, ProductService} from '../../../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class ProductGridService extends TableService<Product> {
  constructor(http: HttpClient, private client: ProductService) {
    super(http, 'Product/GetStationLoading()', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Product): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | Product): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | Product): Observable<any> {
    return this.client.productPatch(data.id, data);
  }

  add(data: any | Product): Observable<any> {
    return this.client.productPost(data);
  }

  protected mapToObject(responseData: any): Product {
    return responseData as Product;
  }
}
