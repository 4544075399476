import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  WorkShift, WorkShiftService,
} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class WorkShiftGridService extends TableService<WorkShift> {
  constructor(http: HttpClient, private clientService: WorkShiftService) {
    super(http, 'WorkShift', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | WorkShift): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | WorkShift): Observable<any> {
    return this.clientService.workShiftPatch(data.id, {deletionDate: new Date()});
  }

  update(data: any | WorkShift): Observable<any> {
    return this.clientService.workShiftPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | WorkShift): Observable<any> {
    return this.clientService.workShiftPost(this.cleanupItem(data));
  }

  protected mapToObject(responseData: any): WorkShift {
    return responseData as WorkShift;
  }
}
