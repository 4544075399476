import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, switchMap, takeUntil} from 'rxjs/operators';
import {ProductService} from '../../../production-progressing-api';

@Injectable()
export class ProductSerialValidatorService implements OnDestroy {

  private destroy$ = new Subject<boolean>();

  constructor(
    private productService: ProductService,
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  doSearchbyCode(codes: Observable<any>, transmittalId: number, contractId: number, debounceMs = 400) {
    return codes
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(debounceMs),
        switchMap((code) => this.rawSearchByCode(code, transmittalId, contractId)),
      );
  }

  rawSearchByCode(code: string, transmittalId: number, contractId: number): Observable<any> {
    code = code.replace(/'/g, '\'\'');
    return this.productService.productGet(
      undefined, 'transmittal($expand=contract($select=id,name,isActive)),assembly',
      `(productserial eq '${code}' or (endswith(productserial, '${code}')`
      + (transmittalId != null ? ` and (transmittalId eq ${transmittalId})` : ``)
      + (contractId != null ? ` and (transmittal/contractId eq ${contractId})` : ``)
      + `)) and deletionDate eq null`,
      undefined, undefined, undefined, true,
    ).pipe(catchError((err) => {
      return of(err);
    }));
  }

}
