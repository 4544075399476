import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {Employee, EmployeeService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class EmployeeGridService extends TableService<Employee> {
  constructor(http: HttpClient, private clientService: EmployeeService) {
    super(http, 'Employee', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Employee): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | Employee): Observable<any> {
    return EMPTY;
  }

  update(data: any | Employee): Observable<any> {
    data = this.clearExtendedProperties(data);
    return this.clientService.employeePatch(data.id, this.cleanupItem(data));
  }

  add(data: any | Employee): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | Employee): Employee {
    delete data.workShift;
    delete data.taskType;
    return data;
  }

  protected mapToObject(responseData: any): Employee {
    return responseData as Employee;
  }

}
