import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {TaskType, TaskTypeService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class TaskTypeGridService extends TableService<TaskType> {
  constructor(http: HttpClient, private clientService: TaskTypeService) {
    super(http, 'TaskType', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | TaskType): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | TaskType): Observable<any> {
    return this.clientService.taskTypePatch(data.id, {name: data.name, deletionDate: new Date()});
  }

  update(data: any | TaskType): Observable<any> {
    return this.clientService.taskTypePatch(data.id, this.cleanupItem(data));
  }

  add(data: any | TaskType): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): TaskType {
    return responseData as TaskType;
  }
}
