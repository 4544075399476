import {Subject} from 'rxjs';

export class ScannerDetectionResults {
  /// Callback after detection of a successful scanning (scanned string in parameter)
  public onComplete = new Subject<{ code: string, qty: number }>();
  /// Callback after receive a char (scanned char in parameter)
  public onReceive = new Subject<string>();
  /// Callback after detection of a unsuccessful scanning (scanned string in parameter)
  public onError = new Subject<any>();
  /// Callback after detection of a successful scan while the scan button was pressed and held down
  /// TODO: Not used
  // public onScanButtonLongPressed = new Subject<{ code: string, qty: number }>();
}
