import {Injectable} from '@angular/core';
import {
  EmployeeSummary,
  ErrorResponse,
  TimesheetService,
} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {TimesheetHelper} from '../../utils/timesheet-helper';

@Injectable({
  providedIn: 'root',
})
export class CrewTimesheetReportGridService extends TableService<EmployeeSummary> {
  constructor(http: HttpClient, private clientService: TimesheetService) {
    super(http, 'Timesheet', '/api/v1/odata/');
    this.settingsName = 'crewSummarySheet';
  }

  getIdUrlPart(data: any | EmployeeSummary): string {
    if (data == null) {
      return '';
    }
    return `/${ data.id }`;
  }

  add(data: any | EmployeeSummary): Observable<any> {
    return this.clientService.timesheetPost(this.cleanupItem(data));
  }

  delete(data: any | EmployeeSummary): Observable<any> {
    return EMPTY;
  }

  update(data: any | EmployeeSummary): Observable<any> {
    return this.clientService.timesheetPatch(data.id, this.cleanupItem(data));
  }

  protected mapToObject(responseData: any | EmployeeSummary): EmployeeSummary {
    responseData.duration = TimesheetHelper.DisplayDuration(responseData);
    responseData.productCount = responseData['productTimesheets@odata.count'];
    delete responseData['productTimesheets@odata.count'];
    return responseData as EmployeeSummary;
  }

  protected addMetaDataToObject(responseData: EmployeeSummary | any): any {
    responseData['$metadata'].noEdit = false;
    return super.addMetaDataToObject(responseData);
  }

  querySummaryByCrew(workDate: Date, crewId: number, state: any, params = {}, asOfDate: Date = null): void {
    const newParams = Object.assign({}, params);
    if (asOfDate != null) {
      newParams['asOfDate'] = asOfDate.toISOString();
    }
    this.fetch(this.tableName
      + `/GetCrewEmployeeSummary(workDate=${encodeURIComponent(String(workDate.toISOString()))},crewId=${crewId != null ? crewId : 0})`
      , state, null, newParams)
      .subscribe({
        next: (x) => {
          super.next(x);
        }, error: (_: ErrorResponse) => {
          super.next({data: [], total: 0});
        }
      });
  }
}
