import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MeetingDetail, MeetingDetailService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class MeetingMemberGridService extends TableService<MeetingDetail> {
  constructor(http: HttpClient, private clientService: MeetingDetailService) {
    super(http, 'MeetingDetail', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | MeetingDetail): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | MeetingDetail): Observable<any> {
    // TODO
    return this.clientService.meetingDetailPatch(data.id);
    // return EMPTY;
  }

  update(data: any | MeetingDetail): Observable<any> {
    return this.clientService.meetingDetailPatch(data.id, this.cleanupItem(data));
    // return EMPTY;
  }

  add(data: any | MeetingDetail): Observable<any> {
    return this.clientService.meetingDetailPost(this.cleanupItem(data));
    // return EMPTY;
  }

  protected mapToObject(responseData: any): MeetingDetail {
    return responseData as MeetingDetail;
  }
}
