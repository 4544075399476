import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {CrewDetailService, CrewDetail} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class CrewMemberGridService extends TableService<CrewDetail> {
  constructor(http: HttpClient, private clientService: CrewDetailService) {
    super(http, 'CrewDetail', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | CrewDetail): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | CrewDetail): Observable<any> {
    return this.clientService.crewDetailPatch(data.id, {deletionDate: new Date(), modifiedDate: new Date()});
    // return EMPTY;
  }

  update(data: any | CrewDetail): Observable<any> {
    delete data.employee;
    delete data.crew;
    delete data.workShift;
    return this.clientService.crewDetailPatch(data.id, this.cleanupItem(data));
    // return EMPTY;
  }

  add(data: any | CrewDetail): Observable<any> {
    return this.clientService.crewDetailPost(this.cleanupItem(data));
    // return EMPTY;
  }

  protected mapToObject(responseData: any): CrewDetail {
    return responseData as CrewDetail;
  }
}
