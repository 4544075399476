import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {NbCardModule, NbDialogRef} from '@nebular/theme';
import {AnnotateFeatures, PdfAnnotateComponent, PdfAnnotateModule} from '@framewerx/pdf-annotate';
import {
  DocumentMarkup,
  DocumentMarkupService,
  DocumentStorage,
  DocumentStorageService,
  DocumentVersion,
  DocumentVersionService,
} from '../../../../../docuwerx-api';
import {CommonModule} from '@angular/common';
import {fabric} from 'fabric';
import {
  BehaviorSubject,
  bindCallback,
  catchError,
  debounceTime,
  EMPTY,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  takeUntil,
  throwError,
} from 'rxjs';
import {UserService} from '../../../../@core/data/users.service';
import {NGXLogger} from 'ngx-logger';
import {filter, switchMap} from 'rxjs/operators';
import {User} from "../../../../@core/data/user";
import {ADMINISTRATOR_ROLE, SUPER_ADMINISTRATOR_ROLE} from "../../../auth/roles.model";

@Component({
  selector: 'ngx-editor-dialog',
  standalone: true,
  imports: [
    PdfAnnotateModule,
    CommonModule,
    NbCardModule,
  ],
  templateUrl: './editor-dialog.component.html',
  styleUrls: ['./editor-dialog.component.scss'],
})
export class EditorDialogComponent implements OnInit, OnChanges, OnDestroy {

  @Input({required: true}) documentVersionId: number;
  @Input() overlayDocumentStorageId: number | null = null;
  private overlayDocumentStorage: DocumentStorage | null = null;

  @Input() title: string = 'Document Preview';
  private saveTrigger = new Subject<fabric.Object>();

  private destroy$ = new Subject<void>();

  @ViewChild('pdfAnnotate') pdfAnnotate: PdfAnnotateComponent;

  loading: boolean = true;
  errorMessage: string = null;
  statusMessage: string = null;
  documentStorage: DocumentStorage = null;

  private documentVersion: DocumentVersion | null = null;
  private documentMarkups: DocumentMarkup[] = [];
  activeDocumentMarkup = new BehaviorSubject<DocumentMarkup>(null);

  // Container holding the readiness state of rendering the annotation
  // Both of these fields need to be filled before rendering can happen on the canvas
  overlayRenderState = new BehaviorSubject<
    {
      canvasReady: boolean,
      markup: DocumentMarkup
    }>({
    canvasReady: false,
    markup: null
  });

  private user: any;
  enabledTools: AnnotateFeatures[] = [
    AnnotateFeatures.Pencil,
    AnnotateFeatures.Text,
    AnnotateFeatures.Rectangle,
    AnnotateFeatures.AddImage
  ];
  isToolbarVisible: boolean = false;
  private isAdmin = false;

  constructor(
    @Optional() protected ref: NbDialogRef<EditorDialogComponent>,
    private logger: NGXLogger,
    private userService: UserService,
    private documentVersionService: DocumentVersionService,
    private documentMarkupService: DocumentMarkupService,
    private documentStorageService: DocumentStorageService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.userService.onUserChange().pipe(
      takeUntil(this.destroy$),
    ).subscribe(user => {
      this.user = user;
    });

    this.userService.onUserChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: User) => {
        // supervisory/administrative
        this.isAdmin = user != null &&
          (user.roles.find((f) => f === ADMINISTRATOR_ROLE || f === SUPER_ADMINISTRATOR_ROLE) != null);

        // Save to disk feature is reserved to ADMINS only
        const indexOfSaveToDisk = this.enabledTools.indexOf(AnnotateFeatures.SaveToDisk);
        if (this.isAdmin) {
          if (indexOfSaveToDisk === -1) {
            this.enabledTools.push(AnnotateFeatures.SaveToDisk);
          }
        } else {
          if (indexOfSaveToDisk !== -1) {
            this.enabledTools.splice(indexOfSaveToDisk, 1);
          }
        }
      });
  }

  ngOnInit() {
    if (this.documentVersionId) {
      this.setup(true);
    }

    let overlayingAnnotations = false;
    this.saveTrigger.pipe(
      takeUntil(this.destroy$),
      filter(e => overlayingAnnotations === false),
      debounceTime(1000), // Adjust the debounce time as needed (in milliseconds)
      switchMap(data => this.saveOverlayToServer()),
    ).subscribe();

    this.overlayRenderState
      .pipe(
        takeUntil(this.destroy$),
        filter(e => e.canvasReady === true),
        filter(e => e.markup != null && e.markup.documentStorage != null),
        filter(e => e.markup.documentStorage.content != null && e.markup.documentStorage.content !== '')
      ).subscribe(e => {
      overlayingAnnotations = true;
      this.pdfAnnotate.overlayAnnotationsFromJson(JSON.parse(e.markup.documentStorage.content));
      overlayingAnnotations = false;
    });

    this.activeDocumentMarkup
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(e => {
        this.overlayRenderState.next({
          canvasReady: this.overlayRenderState.value.canvasReady,
          markup: e
        });
      });
  }

  /**
   * Sets up the Document for viewing.
   * Creates an overlay object if it doesn't exist yet.
   *
   * @param {boolean} updateFromServer - Indicates whether to update from the server.
   */
  private setup(updateFromServer: boolean) {
    this.loading = true;
    let request = of(this.documentVersion);
    if (updateFromServer === true) {
      request = this.getDocumentVersion(this.documentVersionId)
        .pipe(
          catchError((error) => {
            // Handle errors from getDocumentVersion
            this.showError(error);
            return throwError(error);
          }),
        );
    }

    request
      .pipe(
        mergeMap((documentVersion) => {
          this.documentVersion = documentVersion;
          this.documentStorage = documentVersion.documentStorages[0];

          this.cdRef.detectChanges();

          // Handle the loading state of the PDF Annotate component
          this.pdfAnnotate
            .isLoadingPdf
            .pipe(
              takeUntil(this.destroy$),
              filter(e => e === true)
            )
            .subscribe(e => {
              this.overlayRenderState.next({
                canvasReady: false,
                markup: this.activeDocumentMarkup.value
              });
            });

          if (updateFromServer === true) {
            return this.getOverlaysForGivenDocumentVersion(this.documentVersion.documentId, this.documentVersion.id);
          } else {
            return of(this.documentMarkups);
          }
        }),
        catchError((error) => {
          // Handle errors from getDocumentVersion
          this.showError(error);
          return throwError(error);
        }),
        mergeMap((documentMarkups) => {
          this.documentMarkups = documentMarkups;
          let activeDocumentMarkup: DocumentMarkup = null;
          // Try to use the specified overlay document storage
          if (this.overlayDocumentStorageId !== null && this.documentMarkups.length > 0) {
            activeDocumentMarkup = this.documentMarkups.find(e => e.id === this.overlayDocumentStorageId);
          }

          // If the active markup is still null, try to use the first one
          if (activeDocumentMarkup == null && this.documentMarkups.length > 0) {
            activeDocumentMarkup = this.documentMarkups[0];
          }
          return of(activeDocumentMarkup);
        }),
        mergeMap((activeDocumentMarkup) => {
          if (activeDocumentMarkup == null) {
            const newDocumentMarkup: DocumentMarkup = {
              documentId: this.documentVersion.documentId,
              documentVersion: this.documentVersionId,
              documentStorage: {
                name: 'Overlay for ' + this.documentStorage.name,
                content: '',
                contentType: 'application/json',
                path: '',
                contentEncoding: '',
                extension: '',
                storeName: '',
              },
              markupBy: this.user?.name ?? 'SYSTEM',
            };
            return this.documentMarkupService.documentMarkupPost(newDocumentMarkup, null, 'documentStorage');
          } else {
            return of(activeDocumentMarkup);
          }
        }),
      )
      .subscribe({
        next: (documentMarkup: DocumentMarkup) => {
          this.activeDocumentMarkup.next(documentMarkup);
          this.errorMessage = null;
        },
        error: (error) => {
          this.documentVersion = null;
          this.documentMarkups = [];
          this.documentStorage = null;
          this.activeDocumentMarkup.next(null);
        },
      }).add(() => {
      this.loading = false;
      this.statusMessage = null;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.documentVersionId.firstChange)
      this.setup(true);
    // Don't bother re-querying from the server if just the overlay document storage ID is changed
    if (!changes.overlayDocumentStorageId.firstChange)
      this.setup(false);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDocumentVersion(documentVersionId: number): Observable<DocumentVersion> {
    return this.documentVersionService.documentVersionGetById(
      documentVersionId,
      'documentId,version,current,id',
      'documentStorages($select=id,name,storeName,extension,content,contentType,contentEncoding)',
      'response',
    ).pipe(
      map((e) => {
        if (e.ok === true) {
          this.errorMessage = null;
          const documentVersion = e.body;
          if (documentVersion.documentStorages.length > 0) {
            // TODO Consider what should happen if the length is > 1 because this means that there are multiple copies of the same document across stores
            return documentVersion;
          } else {
            throw new Error('The document version was retrieved but we could not find the stored file. Please contact support!');
          }
        } else {
          throw new Error('Failed to retrieve the document with error response: ' + JSON.stringify(e.body));
        }
      }),
    );
  }

  showError(message: string) {
    this.errorMessage = message;
    this.documentStorage = null;
  }

  dismiss() {
    this.ref?.close();
  }

  documentAnnotationEvent($event: fabric.Object) {
    this.saveTrigger.next($event);
  }

  /**
   * Saves the overlay to the server.
   *
   * @return {Observable<void>} An observable that notifies when the save is complete.
   */
  private saveOverlayToServer(): Observable<void> {
    this.statusMessage = 'Saving overlay';
    const activeDocumentMarkup = this.activeDocumentMarkup.value;
    const documentStorage = activeDocumentMarkup?.documentStorage;
    if (activeDocumentMarkup == null || documentStorage == null) {
      this.logger.error('Attempted to save the overlay to the server but there isn\'t an active document markup available yet');
      return EMPTY;
    }

    const callback = bindCallback(this.pdfAnnotate.serializePdf.bind(this.pdfAnnotate), (json) => {
      return json;
    });
    return callback()
      .pipe(
        switchMap((json) => {
          documentStorage.content = json;
          return this.documentStorageService
            .documentStoragePatch(documentStorage.id, documentStorage, null, null, 'response')
            .pipe(
              map(() => {
                this.statusMessage = null;
              })
            );
        })
      );
  }

  private getOverlaysForGivenDocumentVersion(documentId: number, versionNumber: number): Observable<DocumentMarkup[]> {
    this.statusMessage = 'Retrieving annotations';
    return this.documentMarkupService.documentMarkupGet(
      'id,documentId,markupBy,documentVersion,documentStorageId',
      'documentStorage($select=id,name,documentStoreId,path,content,contentType,contentEncoding,extension,storeName)',
      `documentId eq ${documentId} and documentVersion eq ${versionNumber}`,
      null,
      null,
      null,
      null,
      'response',
    ).pipe(
      map((e) => {
        if (e.ok === true) {
          return e.body.value;
        } else {
          throw new Error('Failed to retrieve the document overlay with error response: ' + JSON.stringify(e.body));
        }
      }),
    );
  }

  setupOverlay() {
    if (this.pdfAnnotate.isLoadingPdf.value === false) {
      this.overlayRenderState.next({canvasReady: true, markup: this.activeDocumentMarkup.value});
    }
  }
}

