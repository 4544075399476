import * as printJS from "print-js";
import {VwInventoryItemService} from "../../../../stems-api";
import {NGXLogger} from "ngx-logger";
import {Injectable} from "@angular/core";

@Injectable()
export class InventoryItemHelperService {
  constructor(
    private logger: NGXLogger,
    private inventoryItemService: VwInventoryItemService,
  ) {
  }

  /**
   * Requests the print of a barcode for a specific company.
   *
   * @param {string} barcode - The barcode to print.
   * @param {string} company - The company for which the barcode is being printed.
   */
  requestPrintBarcode(barcode: string, company: string) {
    this.inventoryItemService.vwInventoryItemV1CompanyPrintBarcode(barcode, company)
      .subscribe((x) => {
        this.requestPrintFromBlob(x);
      })
  }

  /**
   * Requests the print of a barcode from a blob.
   *
   * @param {Blob} blob - The blob containing the barcode data.
   */
  requestPrintFromBlob(blob: Blob) {
    const that = this;
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      if (typeof reader.result === "string") {
        // reader.result is of output:
        // "data:application/octet-stream;base64,JVBERi0xLj..."
        // Need to remove the leading portion and retrieve only the base64 portion at the end
        //
        // https://stackoverflow.com/a/24289420/2557554
        const base64result = reader.result.split(',')[1];
        printJS({printable: base64result, type: 'pdf', base64: true, showModal: false})
      } else {
        that.logger.error('Reader result is not of string type. Will not be printing the cut processing barcode PDF.');
      }
    }
  }
}
