import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {Meeting} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class MeetingListGridService extends TableService<Meeting> {
  constructor(http: HttpClient) {
    super(http, 'Meeting', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Meeting): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | Meeting): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | Meeting): Observable<any> {
    return EMPTY;
  }

  add(data: any | Meeting): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): Meeting {
    return responseData as Meeting;
  }
}
