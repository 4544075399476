import {Pipe, PipeTransform} from "@angular/core";

// Based on code by Reactgular (https://stackoverflow.com/users/1031569/reactgular)
// From https://stackoverflow.com/a/57101168/2557554
// Licensed under CC-BY-SA 3.0
@Pipe({name: 'pluck'})
export class PluckPipe implements PipeTransform {
  transform(input: any[], key: string): any {
    return input.map(value => value[key]);
  }
}
