import {Injectable, OnDestroy} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, switchMap, takeUntil} from 'rxjs/operators';
import {VwInventoryItemODataListResponse, VwInventoryItemService} from '../../../stems-api';
import {environment} from '../../../environments/environment';

@Injectable()
export class InventoryItemValidatorService implements OnDestroy {

  private destroy$ = new Subject<boolean>();

  constructor(
    private inventoryItemService: VwInventoryItemService,
  ) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  doSearchbyCode(codes: Observable<any>, debounceMs = 400) {
    return codes
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(debounceMs),
        switchMap((code) => this.rawSearchByCode(code).pipe(takeUntil(this.destroy$))),
      );
  }

  rawSearchByCode(code): Observable<VwInventoryItemODataListResponse> {
    return this.inventoryItemService.vwInventoryItemV1CompanyGet(
      environment.companyLink,
      undefined, undefined, `(barcode eq '${code}')`)
      .pipe(catchError((err) => of(err)));
  }

}
