export * from './asCutProgress.service';
import { AsCutProgressService } from './asCutProgress.service';
export * from './assembly.service';
import { AssemblyService } from './assembly.service';
export * from './attendanceCode.service';
import { AttendanceCodeService } from './attendanceCode.service';
export * from './completionStatus.service';
import { CompletionStatusService } from './completionStatus.service';
export * from './component.service';
import { ComponentService } from './component.service';
export * from './contract.service';
import { ContractService } from './contract.service';
export * from './contractExtraTime.service';
import { ContractExtraTimeService } from './contractExtraTime.service';
export * from './crew.service';
import { CrewService } from './crew.service';
export * from './crewDetail.service';
import { CrewDetailService } from './crewDetail.service';
export * from './dailyTimesheetHeader.service';
import { DailyTimesheetHeaderService } from './dailyTimesheetHeader.service';
export * from './dailyTimesheetSummary.service';
import { DailyTimesheetSummaryService } from './dailyTimesheetSummary.service';
export * from './employee.service';
import { EmployeeService } from './employee.service';
export * from './employeeResponse.service';
import { EmployeeResponseService } from './employeeResponse.service';
export * from './environment.service';
import { EnvironmentService } from './environment.service';
export * from './extraTimeRule.service';
import { ExtraTimeRuleService } from './extraTimeRule.service';
export * from './extraWorkOrder.service';
import { ExtraWorkOrderService } from './extraWorkOrder.service';
export * from './inventoryItem.service';
import { InventoryItemService } from './inventoryItem.service';
export * from './latestProductNote.service';
import { LatestProductNoteService } from './latestProductNote.service';
export * from './latestProductTimesheet.service';
import { LatestProductTimesheetService } from './latestProductTimesheet.service';
export * from './latestProductTimesheetCompletionStatusPerCompletionStatus.service';
import { LatestProductTimesheetCompletionStatusPerCompletionStatusService } from './latestProductTimesheetCompletionStatusPerCompletionStatus.service';
export * from './latestProductTimesheetPerTaskType.service';
import { LatestProductTimesheetPerTaskTypeService } from './latestProductTimesheetPerTaskType.service';
export * from './markStatus.service';
import { MarkStatusService } from './markStatus.service';
export * from './meeting.service';
import { MeetingService } from './meeting.service';
export * from './meetingDetail.service';
import { MeetingDetailService } from './meetingDetail.service';
export * from './metadata.service';
import { MetadataService } from './metadata.service';
export * from './product.service';
import { ProductService } from './product.service';
export * from './productLocation.service';
import { ProductLocationService } from './productLocation.service';
export * from './productNote.service';
import { ProductNoteService } from './productNote.service';
export * from './productTimesheet.service';
import { ProductTimesheetService } from './productTimesheet.service';
export * from './productTimesheetAndNotes.service';
import { ProductTimesheetAndNotesService } from './productTimesheetAndNotes.service';
export * from './productTimesheetCompletionStatus.service';
import { ProductTimesheetCompletionStatusService } from './productTimesheetCompletionStatus.service';
export * from './question.service';
import { QuestionService } from './question.service';
export * from './report.service';
import { ReportService } from './report.service';
export * from './sampleData.service';
import { SampleDataService } from './sampleData.service';
export * from './shipping.service';
import { ShippingService } from './shipping.service';
export * from './shippingDetail.service';
import { ShippingDetailService } from './shippingDetail.service';
export * from './station.service';
import { StationService } from './station.service';
export * from './stationGroup.service';
import { StationGroupService } from './stationGroup.service';
export * from './stationLocation.service';
import { StationLocationService } from './stationLocation.service';
export * from './stationProduct.service';
import { StationProductService } from './stationProduct.service';
export * from './stemsWorkstation.service';
import { StemsWorkstationService } from './stemsWorkstation.service';
export * from './systemOption.service';
import { SystemOptionService } from './systemOption.service';
export * from './taskType.service';
import { TaskTypeService } from './taskType.service';
export * from './taskTypeGroup.service';
import { TaskTypeGroupService } from './taskTypeGroup.service';
export * from './timeCard.service';
import { TimeCardService } from './timeCard.service';
export * from './timesheet.service';
import { TimesheetService } from './timesheet.service';
export * from './tradeUnion.service';
import { TradeUnionService } from './tradeUnion.service';
export * from './transmittal.service';
import { TransmittalService } from './transmittal.service';
export * from './workArea.service';
import { WorkAreaService } from './workArea.service';
export * from './workShift.service';
import { WorkShiftService } from './workShift.service';
export * from './workTrade.service';
import { WorkTradeService } from './workTrade.service';
export * from './workTradeUnion.service';
import { WorkTradeUnionService } from './workTradeUnion.service';
export const APIS = [AsCutProgressService, AssemblyService, AttendanceCodeService, CompletionStatusService, ComponentService, ContractService, ContractExtraTimeService, CrewService, CrewDetailService, DailyTimesheetHeaderService, DailyTimesheetSummaryService, EmployeeService, EmployeeResponseService, EnvironmentService, ExtraTimeRuleService, ExtraWorkOrderService, InventoryItemService, LatestProductNoteService, LatestProductTimesheetService, LatestProductTimesheetCompletionStatusPerCompletionStatusService, LatestProductTimesheetPerTaskTypeService, MarkStatusService, MeetingService, MeetingDetailService, MetadataService, ProductService, ProductLocationService, ProductNoteService, ProductTimesheetService, ProductTimesheetAndNotesService, ProductTimesheetCompletionStatusService, QuestionService, ReportService, SampleDataService, ShippingService, ShippingDetailService, StationService, StationGroupService, StationLocationService, StationProductService, StemsWorkstationService, SystemOptionService, TaskTypeService, TaskTypeGroupService, TimeCardService, TimesheetService, TradeUnionService, TransmittalService, WorkAreaService, WorkShiftService, WorkTradeService, WorkTradeUnionService];
