/**
 * Production Progressing API
 * An API for production progressing and shop time tracking.
 *
 * The version of the OpenAPI document: v1
 * Contact: david@framewerx.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProductLoadStatus = 'Loading' | 'Loaded' | 'Unloading' | 'Unloaded';

export const ProductLoadStatus = {
    Loading: 'Loading' as ProductLoadStatus,
    Loaded: 'Loaded' as ProductLoadStatus,
    Unloading: 'Unloading' as ProductLoadStatus,
    Unloaded: 'Unloaded' as ProductLoadStatus
};

