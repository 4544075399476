import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {ActiveContractLoadService} from './active-contract-load.service';
import {ActiveStationService} from './active-station.service';
import {ActiveTimesheetsService} from './active-timesheets.service';
import {DropdownListService} from './dropdown-list.service';
import {EmployeeSelectService} from './employee-select.service';
import {EmployeeService} from './employee.service';
import {AssemblyProductGridService} from './grid/assembly-product-grid.service';
import {CompletionStatusTypeGridService} from './grid/completion-status-type-grid-service';
import {ComponentGridService} from './grid/component-summary-grid.service';
import {ContractGridService} from './grid/contract-grid.service';
import {CrewlistGridService} from './grid/crewlist-grid.service';
import {CrewMemberGridService} from './grid/crewmember-grid.service';
import {EmployeeGridService} from './grid/employee-grid.service';
import {EmployeeSearchGridService} from './grid/employee-search-grid.service';
import {EmployeeTimesheetsGridService} from './grid/employee-timesheets-grid.service';
import {EwoGridService} from './grid/ewo-grid.service';
import {MeetingListGridService} from './grid/meeting-list-grid.service';
import {MeetingMemberGridService} from './grid/meetingmember-grid.service';
import {ProductLocationGridService} from './grid/product-location-grid.service';
import {ProductTimesheetAndNotesGridService} from './grid/product-timesheet-and-notes-grid.service';
import {ProductTimesheetGridService} from './grid/product-timesheet-grid.service';
import {
  LatestProductTimesheetGridService
} from './grid/production-progressing/product/latest-product-timesheet-grid.service';
import {ProductGridService} from './grid/production-progressing/product/product-grid.service';
import {QuestionGridService} from './grid/question-grid.service';
import {ShippingLoadListGridService} from './grid/shipping-list-grid.service';
import {ShippingLoadGridService} from './grid/shipping-load-grid.service';
import {StatePersistingService} from '@framewerx/kendo-angular-grid';
import {StationListGridService} from './grid/station-list-grid.service';
import {StationLocationGridService} from './grid/station-location-grid.service';
import {SystemOptionGridService} from './grid/system-option-grid.service';
import {TaskTypeGridService} from './grid/task-type-grid.service';
import {TimecardGridService} from './grid/timecard-grid.service';
import {TimesheetApprovalGridService} from './grid/timesheet-approval-grid.service';
import {TimesheetProductGridService} from './grid/timesheet-product-grid.service';
import {TransmittalGridService} from './grid/transmittal-grid.service';
import {WorkShiftGridService} from './grid/workshift-grid.service';
import {ProductBasketService} from './product-basket.service';
import {StateService} from './state.service';
import {StemsDropdownListService} from './stems-dropdown-list.service';
import {SystemOptionGetService} from './systemoptions.service';
import {TimesheetFormValidatorService} from './timesheet-form-validator.service';
import {UserService} from './users.service';
import {StatusSummaryGridService} from './grid/status-summary-grid.service';
import {ProductStatusSummaryGridService} from './grid/product-status-summary-grid.service';
import {ShippingDetailGridService} from './grid/shipping-detail-grid.service';
import {CrewTimesheetReportGridService} from './grid/crew-timesheet-report-grid.service';
import {AscutGridService} from './grid/ascut-grid.service';
import {AscutInvGridService} from './grid/ascut-inv-grid.service';
import {CustomPathBuilderService} from "./custom-path-builder.service";
import {TaskBuilderService} from "../../pages/shared/task-builder.service";
import {InventoryItemHelperService} from "../../pages/barcode/inventory-item-scanner/inventory-item-helper.service";

const SERVICES = [
  CookieService,
  UserService,
  EmployeeService,
  StateService,
  StatePersistingService,
  ProductGridService,
  LatestProductTimesheetGridService,
  ActiveStationService,
  ProductBasketService,
  EmployeeGridService,
  ActiveTimesheetsService,
  EmployeeTimesheetsGridService,
  ContractGridService,
  ComponentGridService,
  TransmittalGridService,
  EmployeeSelectService,
  EmployeeSearchGridService,
  SystemOptionGetService,
  CrewlistGridService,
  CrewMemberGridService,
  TimesheetApprovalGridService,
  StationListGridService,
  MeetingListGridService,
  MeetingMemberGridService,
  TimecardGridService,
  TimesheetProductGridService,
  WorkShiftGridService,
  TaskTypeGridService,
  DropdownListService,
  AscutGridService,
  AscutInvGridService,
  StemsDropdownListService,
  SystemOptionGridService,
  TimesheetFormValidatorService,
  ProductLocationGridService,
  QuestionGridService,
  ProductTimesheetGridService,
  ProductTimesheetAndNotesGridService,
  ShippingLoadGridService,
  ShippingLoadListGridService,
  ShippingDetailGridService,
  StationLocationGridService,
  EwoGridService,
  ActiveContractLoadService,
  CompletionStatusTypeGridService,
  AssemblyProductGridService,
  StatusSummaryGridService,
  ProductStatusSummaryGridService,
  CrewTimesheetReportGridService,
  CustomPathBuilderService,
  TaskBuilderService,
  InventoryItemHelperService,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
  exports: [],
})
export class DataModule {
  static forRoot(): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
