import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {
  ODataValueOfIEnumerableOfSystemOption,
  SystemOptionService as SystemOptionApiService,
} from '../../../production-progressing-api';

@Injectable({providedIn: 'root'})
export class SystemOptionGetService {

  // Day start is a JS date with the hours set by "Start of Day" System Option
  protected dayStart: Subject<Date> = new Subject<Date>();

  constructor(public systemOptionService: SystemOptionApiService) {
    // this.refreshSystemOptions();
  }

  refreshSystemOptions() {
    // Start of Day is a string representing the hours e.g. "05:00"
    this.getSystemOptionByName('Start of Day')
      .subscribe((e) => {
        const day = new Date();
        day.setHours(Number(e.value[0].value.split(':')[0]), Number(e.value[0].value.split(':')[1]));
        this.dayStart.next(day);
      });
  }

  onDayStartChange(): Observable<Date> {
    return this.dayStart;
  }

  getSystemOptionByName(id: string): Observable<ODataValueOfIEnumerableOfSystemOption> {
    return this.systemOptionService.systemOptionGet(undefined, undefined, `name eq '${id}'`);
  }
}
