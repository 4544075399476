import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { ContractService, Contract } from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class ContractGridService extends TableService<Contract> {
  constructor(http: HttpClient, private client: ContractService) {
    super(http, 'Contract', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Contract): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | Contract): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | Contract): Observable<any> {
    return this.client.contractPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | Contract): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): Contract {
    return responseData as Contract;
  }
}
