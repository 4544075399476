import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {TableService} from '@framewerx/kendo-angular-grid';
import {AsCut, VwInventoryItem} from '../../../../stems-api';
import {ErrorResponse} from '../../../../production-progressing-api';

@Injectable()
export class AscutInvGridService extends TableService<VwInventoryItem> {
  constructor(http: HttpClient, apiRootUrl = '/api/v1/odata/') {
    super(http, 'AsCut', apiRootUrl);
  }

  queryCandidates(ascuthdrId: number, state: any, params = {}): void {
    this.fetch(this.tableName
      + `/Default.GetViableStockFromCut(ascuthdrId=${ascuthdrId})`, state, null, params)
      .subscribe({
        next: (x) => {
          super.next(x);
        }, error: (_: ErrorResponse) => {
          super.next({data: [], total: 0});
        }
      });
  }

  getIdUrlPart(data: any | VwInventoryItem): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | VwInventoryItem): Observable<any> {
    // TODO
    return EMPTY;
  }

  update(data: any | VwInventoryItem): Observable<any> {
    return EMPTY;
  }

  add(data: any | VwInventoryItem): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | VwInventoryItem): VwInventoryItem {
    return data;
  }

  protected mapToObject(responseData: any): VwInventoryItem {
    return responseData as VwInventoryItem;
  }

  protected addMetaDataToObject(responseData: any): any {
    return super.addMetaDataToObject(responseData);
  }
}
