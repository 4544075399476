import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {IEmployeeDay} from '../../pages/admin/models/employee-day';
import {takeUntil} from "rxjs/operators";

@Injectable()
export class ActiveTimesheetsService implements OnDestroy {

  static readonly StorageKey = 'activeTimesheets';

  protected activeTimesheets: BehaviorSubject<IEmployeeDay> = new BehaviorSubject(null);

  private destroy$ = new Subject<boolean>();

  constructor() {
    if (sessionStorage.getItem(ActiveTimesheetsService.StorageKey)) {
      const timesheet: IEmployeeDay = JSON.parse(sessionStorage.getItem(ActiveTimesheetsService.StorageKey));
      this.activeTimesheets.next(timesheet);
      this.refreshCachedTimesheet(timesheet);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  refreshCachedTimesheet(timesheet: IEmployeeDay = null) {
    if (timesheet != null && timesheet.employeeId != null && timesheet.timesheetDate != null) {
      this.getTimesheets(timesheet.supervisorId, timesheet.employeeId, timesheet.timesheetDate)
        .pipe(takeUntil(this.destroy$))
        .subscribe((e) => this.activeTimesheets.next(e));
    }
  }

  getTimesheets(supervisorId: number, id: number, day: Date): Observable<IEmployeeDay> {
    const stDate = new Date(day);
    const ndDate = new Date(stDate);
    ndDate.setDate(ndDate.getDate() + 1);

    const newTime: IEmployeeDay = {
      supervisorId,
      employeeId: id,
      timesheetDate: day,
    };
    return of(newTime);
  }

  onActiveTimesheetChange(): Observable<IEmployeeDay> {
    return this.activeTimesheets;
  }

  publishTimesheet(timesheet: IEmployeeDay) {
    this.activeTimesheets.next(timesheet);
    sessionStorage.setItem(ActiveTimesheetsService.StorageKey, JSON.stringify(timesheet));
  }
}
