import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ComponentService, Component} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class ComponentGridService extends TableService<Component> {
  constructor(http: HttpClient, private client: ComponentService) {
    super(http, 'Component', '/api/v1/odata/');
  }

  public query2(stationGroupName: string, productLoadStatus: string, state: any, params = {}): void {
    this.fetch(this.tableName + `/GetStationGroupComponentSummary(stationGroupName='${encodeURIComponent(String(stationGroupName))}',productLoadStatus='${encodeURIComponent(String(productLoadStatus))}')`, state, null, params)
      .subscribe((x) => super.next(x));
  }

  getIdUrlPart(data: any | Component): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | Component): Observable<any> {
    return EMPTY;
  }

  update(data: any | Component): Observable<any> {
    // return this.client.contractPatch(data.id, data);
    return EMPTY;
  }

  add(data: any | Component): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): Component {
    return responseData as Component;
  }
}
