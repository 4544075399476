import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {TableService} from '@framewerx/kendo-angular-grid';
import {AsCut} from '../../../../stems-api';
import {ErrorResponse} from '../../../../production-progressing-api';

@Injectable()
export class AscutGridService extends TableService<AsCut> {
  constructor(http: HttpClient, apiRootUrl = '/api/v1/odata/') {
    super(http, 'AsCut', apiRootUrl);
  }

  // query() {
  //   super.next({data: [], total: 0});
  // }

  queryInvItemCandidates(inventoryItemId: number, state: any, params = {}): void {
    this.fetch(this.tableName
      + `/Default.GetViableCutsFromStock(inventoryItemId=${inventoryItemId},barcode=null)`, state, null, params)
      .subscribe({
        next: (x) => {
          super.next(x);
        },
        error: (_: ErrorResponse) => {
          super.next({data: [], total: 0});
        }
      });
  }

  queryCandidates(state: any, params = {}): void {
    this.fetch(this.tableName
      + '/Default.GetViableCuts()', state, null, params)
      .subscribe({
        next: (x) => {
          super.next(x);
        }, error: (_: ErrorResponse) => {
          super.next({data: [], total: 0});
        }
      });
  }

  getIdUrlPart(data: any | AsCut): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | AsCut): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | AsCut): Observable<any> {
    return EMPTY;
  }

  add(data: any | AsCut): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | AsCut): AsCut {
    return data;
  }

  protected mapToObject(responseData: any): AsCut {
    return responseData as AsCut;
  }

  protected addMetaDataToObject(responseData: any): any {
    return super.addMetaDataToObject(responseData);
  }
}
