import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {Transmittal, TransmittalService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class TransmittalGridService extends TableService<Transmittal> {
  constructor(http: HttpClient, private client: TransmittalService) {
    super(http, 'Transmittal/AddExtraInfo()', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Transmittal): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }


  delete(data: any | Transmittal): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | Transmittal): Observable<any> {
    return this.client.transmittalPatch(data.id, this.cleanupItem(data));
  }

  protected cleanupItem(data: any): any {
    delete data.productCount;
    delete data.quantity;
    delete data.products;
    delete data['@odata.type'];
    delete data.weight;
    return super.cleanupItem(data);
  }

  add(data: any | Transmittal): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): Transmittal {
    responseData.productCount = responseData['products@odata.count'];
    delete responseData['products@odata.count'];
    delete responseData['@odata.type'];
    return responseData as Transmittal;
  }
}
