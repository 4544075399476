import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ExtraWorkOrder, ExtraWorkOrderService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class EwoGridService extends TableService<ExtraWorkOrder> {
  constructor(http: HttpClient, private client: ExtraWorkOrderService) {
    super(http, 'ExtraWorkOrder', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | ExtraWorkOrder): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | ExtraWorkOrder): Observable<any> {
    return EMPTY;
  }

  update(data: any | ExtraWorkOrder): Observable<any> {
    return this.client.extraWorkOrderPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | ExtraWorkOrder): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): ExtraWorkOrder {
    return responseData as ExtraWorkOrder;
  }
}
