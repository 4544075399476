import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {Crew} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class CrewlistGridService extends TableService<Crew> {
  // Remove Default. prefix
  queryAsOfNamespace = '';

  constructor(http: HttpClient) {
    super(http, 'Crew', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | Crew): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | Crew): Observable<any> {
    return EMPTY;
  }

  update(data: any | Crew): Observable<any> {
    return EMPTY;
  }

  add(data: any | Crew): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): Crew {
    return responseData as Crew;
  }
}
