import {Injectable, OnDestroy} from '@angular/core';
import {Params} from '@angular/router';
import {BehaviorSubject, Observable, of, Subject, Subscription} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {
  Contract,
  ContractService,
  ExtraWorkOrder,
  ExtraWorkOrderService,
  Product,
  ProductService,
  Station,
  StationService,
  TaskType,
  TaskTypeGroup,
  TaskTypeGroupService,
  TaskTypeService,
  Transmittal,
  TransmittalService,
} from '../../../production-progressing-api';
import {AsCutService} from '../../../stems-api';
import {ActiveStationService} from '../../@core/data/active-station.service';
import {ErrorService} from './errors/error.service';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../environments/environment';

export interface ITaskBuilderPath {
  taskTypeId?: number;
  contractId?: number;
  groupId?: number;
  stationId: number;
  ewoId?: number;
  taskTypeGroup: string;
  productId?: number;
}

@Injectable()
export class TaskBuilderService implements OnDestroy {

  static GetContractGroupStationEwoIdInPath(params: Observable<Params>): Observable<ITaskBuilderPath> {
    return params
      .pipe(map((p) => {
        const taskTypeId = p['taskTypeId'];
        const taskTypeGroup = p['taskTypeGroup'];
        const contractId = p['contractId'];
        const groupId = p['groupId'];
        const stationId = p['stationId'];
        const ewoId = p['ewoId'];
        const productId = p['productId'];
        return {
          taskTypeId,
          taskTypeGroup,
          contractId,
          groupId,
          stationId,
          ewoId,
          productId,
        } as ITaskBuilderPath;
      }));
  }

  taskType$ = new BehaviorSubject<TaskType>(null);

  taskTypeGroup$ = new BehaviorSubject<TaskTypeGroup>(null);

  station$ = new BehaviorSubject<Station>(null);
  contract$ = new BehaviorSubject<Contract>(null);
  group$ = new BehaviorSubject<Transmittal>(null);
  ewo$ = new BehaviorSubject<ExtraWorkOrder>(null);
  product$ = new BehaviorSubject<Product>(null);
  // asCut$ = new BehaviorSubject<AsCut>(null);

  // Useful for each selector knowing if the EWO should be bypassed
  // Don't use a BehaviorSubject to avoid old data being processed
  bypassEwo$ = new Subject<boolean>();
  // Indicates if the user pressed the app back button or browser back button
  goBack = false;

  destroy$ = new Subject<boolean>();

  private bypassEwoSubscription: Subscription = null;

  constructor(
    private logger: NGXLogger,
    private errorService: ErrorService,
    private stationService: StationService,
    private taskTypeService: TaskTypeService,
    private taskTypeGroupService: TaskTypeGroupService,
    private contractService: ContractService,
    private productService: ProductService,
    private transmittalService: TransmittalService,
    private ewoService: ExtraWorkOrderService,
    private cutService: AsCutService) {
    this.station$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.fillBypassEwo());
    this.contract$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => this.fillBypassEwo());
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  getTaskTypeGroupByName(name: string, forceRefresh = false): Observable<TaskTypeGroup | null> {
    if (name == null) {
      return of(null);
    }

    const existingTaskType = this.taskTypeGroup$.value;
    if (existingTaskType != null && existingTaskType.name === name && forceRefresh === false) {
      this.taskTypeGroup$.next(this.taskTypeGroup$.value);
      return this.taskTypeGroup$.pipe(takeUntil(this.destroy$));
    }

    return this.taskTypeGroupService.taskTypeGroupGet(
      null,
      null,
      `name eq '${name}'`,
    ).pipe(map((e) => {
      if (e.value != null && e.value.length > 0) {
        this.taskTypeGroup$.next(e.value[0]);
        return e.value[0];
      }
      this.taskTypeGroup$.next(null);
      return null;
    }));
  }

  getTaskTypeById(id: number, forceRefresh = false): Observable<TaskType> {
    if (id == null || isNaN(id)) {
      return of(null);
    }

    const existingTaskType = this.taskType$.value;
    if (existingTaskType != null && existingTaskType.id === id && forceRefresh === false) {
      this.taskType$.next(this.taskType$.value);
      return this.taskType$.pipe(takeUntil(this.destroy$));
    }

    return this.taskTypeService.taskTypeGetById(
      id,
      undefined,
      'contract,taskTypeGroup',
    ).pipe(map((e) => {
      this.taskType$.next(e);
      return e;
    }));
  }

  getStationById(id: number, forceRefresh = false): Observable<Station> {
    if (id == null || isNaN(id)) {
      return of(null);
    }

    const existingStation = this.station$.value;
    if (existingStation != null && existingStation.id === id && forceRefresh === false) {
      this.station$.next(this.station$.value);
      return this.station$.pipe(takeUntil(this.destroy$));
    }

    return this.stationService.stationGetById(
      id,
      ActiveStationService.StationSelectParam,
      ActiveStationService.StationExpandParam,
    ).pipe(map((e) => {
      this.station$.next(e);
      return e;
    }));
  }

  getStationDetails(id: number): Observable<Station> {
    return this.stationService.stationGetById(
      id, undefined, 'stemsWorkstation');
    // .pipe(map((e) =>
    //   // this.station$.next(e);
    //   return e
    // ));
  }

  getEwoById(id: number, forceRefresh = false): Observable<ExtraWorkOrder> {
    if (id == null || isNaN(id)) {
      return of(null);
    }

    const existingEwo = this.ewo$.value;
    if (existingEwo != null && existingEwo.id === id && forceRefresh === false) {
      this.ewo$.next(this.ewo$.value);
      return this.ewo$.pipe(takeUntil(this.destroy$));
    }

    return this.ewoService.extraWorkOrderGetById(
      id,
      undefined,
      undefined,
    ).pipe(map((e) => {
      this.ewo$.next(e);
      return e;
    }));
  }

  getContractById(id: number, forceRefresh = false): Observable<Contract> {
    if (id == null || isNaN(id)) {
      return of(null);
    }

    const existingContract = this.contract$.value;
    if (existingContract != null && existingContract.id === id && forceRefresh === false) {
      this.contract$.next(this.contract$.value);
      return this.contract$.pipe(takeUntil(this.destroy$));
    }

    return this.contractService.contractGetById(
      id,
      undefined,
      undefined,
    ).pipe(map((e) => {
      this.contract$.next(e);
      return e;
    }));
  }

  getGroupById(id: number, forceRefresh = false): Observable<Transmittal> {
    if (id == null || isNaN(id)) {
      return of(null);
    }

    const existingGroup = this.group$.value;
    if (existingGroup != null && existingGroup.id === id && forceRefresh === false) {
      this.group$.next(this.group$.value);
      return this.group$.pipe(takeUntil(this.destroy$));
    }

    return this.transmittalService.transmittalGetById(
      id,
      undefined,
      undefined,
    ).pipe(map((e) => {
      this.group$.next(e);
      return e;
    }));
  }

  /**
   * Get the cut, based on ascuthdrid.
   * @returns ascut object
   */
  getAsCutDetailsFromBarcode(sectionId: string, gradeId: string, inventoryId: string, jobnum: string) {
    return this.cutService.asCutV1CompanyGet(environment.companyLink,
      undefined,
      undefined,
      `(sectionId eq ${sectionId} and gradeId eq ${gradeId} and inventoryId eq ${inventoryId} and jobnum eq '${jobnum}' and completed eq false)`
    ).pipe(map((e) => {
      // this.asCut$.next(e.value);
      this.logger.log(e.value);
      return e;
    }));
  }

  /**
   * Get the cut, based on ascuthdrid.
   * @returns ascut object
   */
  getAsCutDetailsFromInventoryItem(inventoryItemId: number, jobnum: string, stemsWorkstationId: number) {
    return this.cutService.asCutV1CompanyGetViableCutsFromStock(environment.companyLink,
      undefined,
      inventoryItemId,
      undefined,
      'section,grade,company',
      `(jobnum eq '${jobnum}' and completed eq false and (machineId eq 0 or machineId eq ${stemsWorkstationId}))`
    ).pipe(map((e) => {
      // this.asCut$.next(e.value);
      this.logger.log(e.value);
      return e;
    }));
  }

  /**
   * Get the contract, prioritizing station contract setup.
   * @returns Contract of the station; otherwise, the chosen Contract or null.
   */
  getPreferredContract(): Contract | null {
    const station = this.station$.value;
    if (station != null
      && station.stationGroup != null
      && station.stationGroup.transmittal != null
      && station.stationGroup.transmittal.contract != null) {
      return station.stationGroup.transmittal.contract;
    }

    return this.contract$.value;
  }

  /**
   * Get the group, prioritizing station group setup.
   * @returns Group of the station; otherwise, the chosen Group or null.
   */
  getPreferredGroup(): Transmittal | null {
    const station = this.station$.value;
    if (station != null
      && station.stationGroup != null
      && station.stationGroup.transmittal != null) {
      return station.stationGroup.transmittal;
    }

    return this.group$.value;
  }

  /**
   * Get the station, prioritizing station group setup.
   * @returns Station of the station; otherwise, the chosen Group or null.
   */
  getPreferredStation(): Station | null {
    const station = this.station$.value;
    if (station != null
      && station.stationGroup != null) {
      return station;
    }
    return this.station$.value;
  }

  private fillBypassEwo() {
    let contractName = null;
    const stationValue = this.station$.value;
    const contractValue = this.contract$.value;

    if (stationValue != null && stationValue.stationGroup != null
      && stationValue.stationGroup.transmittal != null
      && stationValue.stationGroup.transmittal.contract != null) {
      contractName = stationValue.stationGroup.transmittal.contract.name;
    } else if (contractValue != null) {
      contractName = contractValue.name;
    }

    if (contractName != null) {
      if (this.bypassEwoSubscription != null) {
        // Cancel this request if it's already running
        this.bypassEwoSubscription.unsubscribe();
      }
      this.bypassEwoSubscription = this.getEwoByContractName(contractName)
        .pipe(takeUntil(this.destroy$))
        .subscribe((f) => {
          this.bypassEwo$.next(f != null && f === 0);
        });
    }
  }

  private getEwoByContractName(name: string): Observable<number> {
    return this.ewoService
      .extraWorkOrderGet(
        undefined, undefined,
        `isActive eq true and jobNum eq '${encodeURIComponent(name)}'`,
        undefined, 1, 0, true,
      )
      .pipe(takeUntil(this.destroy$))
      .pipe(map(
        (e) => {
          return e['@odata.count'];
        },
      ));
  }

  getProductById(id: number, forceRefresh = false): Observable<Product> {
    if (id == null || isNaN(id)) {
      return of(null);
    }

    const existingContract = this.product$.value;
    if (existingContract != null && existingContract.id === id && forceRefresh === false) {
      this.product$.next(this.product$.value);
      return this.product$.pipe(takeUntil(this.destroy$));
    }

    return this.productService.productGetById(
      id,
      undefined,
      'transmittal($expand=contract),assembly',
    ).pipe(map((e) => {
      if (e != null) {
        this.product$.next(e);
      }
      return e;
    }));
  }
}
