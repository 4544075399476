import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Station, StationService} from '../../../production-progressing-api';

@Injectable()
export class ActiveStationService {

  static readonly StorageKey = 'activeStation';

  public static readonly StationSelectParam = 'id,name,stationGroup,stationGroupId';
  public static readonly StationExpandParam = 'stemsWorkstation($select=id,workstationId),stationGroup($select=id,name,closedDate,transmittal,transmittalId;$expand=transmittal($expand=contract($select=name)))';

  protected activeStation: BehaviorSubject<Station> = new BehaviorSubject(null);

  constructor(
    private stationService: StationService) {
    if (sessionStorage.getItem(ActiveStationService.StorageKey)) {
      const station: Station = JSON.parse(sessionStorage.getItem(ActiveStationService.StorageKey));
      this.activeStation.next(station);
      this.refreshCachedStation(station);
    }
  }

  refreshCachedStation(station: Station = null) {
    if (station != null && station.id != null) {
      this.getStationById(station.id)
        .subscribe((e) => this.activeStation.next(e));
    }
  }

  getStationById(id: number): Observable<Station> {
    return this.stationService.stationGetById(
      id,
      ActiveStationService.StationSelectParam,
      ActiveStationService.StationExpandParam,
      'body');
  }

  onActiveStationChange(): Observable<Station> {
    return this.activeStation;
  }

  publishStation(station: Station) {
    this.activeStation.next(station);
    sessionStorage.setItem(ActiveStationService.StorageKey, JSON.stringify(station));
  }
}
