import {Timesheet} from '../../../production-progressing-api';
import {formatDuration, intervalToDuration} from "date-fns";

export class TimesheetHelper {

  /***
   * Converts a Timesheet's Start and End times into a string duration of format (HH:mm:ss).
   * @param timesheet The timesheet to process.
   * @returns A duration as a string in the format 1d 02h30m00s
   */
  static DisplayDuration(timesheet: Timesheet): string {
    if (timesheet.endTime != null) {
      // Custom formatting by locale
      // See https://github.com/date-fns/date-fns/issues/2134#issuecomment-788212525
      const formatDistanceLocale = {
        xSeconds: '{{count}}s',
        xMinutes: '{{count}}m',
        xHours: '{{count}}h',
        xDays: '{{count}}d '
      }
      const zeroPad = (num) => String(num).padStart(2, "0");
      const shortEnLocale: Locale = {
        formatDistance: (token: string, count: any) => {
          // Ignore zero padding on days e.g. 1d 02h30m00s
          const paddedCount = token === 'xDays' ? count : zeroPad(count);
          return formatDistanceLocale[token].replace('{{count}}', paddedCount);
        }
      };
      const duration = intervalToDuration({start: timesheet.startTime, end: timesheet.endTime});
      // const duration = {minutes: differenceInMinutes(timesheet.endTime, timesheet.startTime)};
      return formatDuration(duration, {
        format: ['days', 'hours', 'minutes', 'seconds'],
        locale: shortEnLocale,
        zero: true,
        delimiter: ''
      });
    } else {
      return '';
    }
  }
}
