import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {EmployeeSummary, ErrorResponse, Timesheet} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';
import {FilterDescriptor} from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import {CompositeFilterDescriptor} from '@progress/kendo-data-query';
import {GridDataResult} from '@progress/kendo-angular-grid';

@Injectable()
export class TimesheetApprovalGridService extends TableService<EmployeeSummary> {
  constructor(http: HttpClient) {
    super(http, 'Timesheet', '/api/v1/odata/');
  }

  query() {
    super.next({data: [], total: 0});
  }

  querySummary(workDate: Date, state: any, params = {}, asOfDate: Date = null): void {
    this.fetch(this.tableName
      + `/GetEmployeeSummary(workDate=${encodeURIComponent(String(workDate.toISOString()))})`
      , state, null, params)
      .subscribe({
        next: (x) => {
          super.next(x);
        }, error: (_: ErrorResponse) => {
          super.next({data: [], total: 0});
        }
      });
  }

  querySummaryByCrew(workDate: Date, crewId: number, state: any, params = {}, asOfDate: Date = null): void {
    const newParams = Object.assign({}, params);
    newParams['asOfDate'] = asOfDate.toISOString();
    this.fetch(this.tableName
      + `/GetCrewEmployeeSummary(workDate=${encodeURIComponent(String(workDate.toISOString()))},crewId=${crewId != null ? crewId : 0})`
      , state, null, newParams)
      .subscribe({
        next: (x) => {
          super.next(x);
        }, error: (_: ErrorResponse) => {
          super.next({data: [], total: 0});
        }
      });
  }

  // fixes the case sensitive searching for timesheet approvals
  protected fetch(tableName: string, state: any, data?: any, params?: {}): Observable<GridDataResult> {
    const nstate = structuredClone(state);

    function updateFilters(filter: (FilterDescriptor | CompositeFilterDescriptor)[]) {
      filter.forEach((p) => {
        const fld = p as FilterDescriptor;
        if (fld != null && typeof fld.field === 'string' && fld.field.toString().indexOf('employee.') >= 0 && typeof fld.value === 'string') {
          if (fld) {
            fld.ignoreCase = true;
            fld.value = fld.value.toLowerCase();
          }
        }
        const filt = p as CompositeFilterDescriptor;
        if (filt != null && filt.filters != null) {
          updateFilters(filt.filters);
        }
      });
    }

    updateFilters(nstate.filter.filters);

    return super.fetch(tableName, nstate, data, params);
  }

  getIdUrlPart(data: any | EmployeeSummary): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | EmployeeSummary): Observable<any> {
    // TODO
    // return this.client.delete(data.id);
    return EMPTY;
  }

  update(data: any | EmployeeSummary): Observable<any> {
    return EMPTY;
  }

  add(data: any | EmployeeSummary): Observable<any> {
    return EMPTY;
  }

  clearExtendedProperties(data: any | EmployeeSummary): EmployeeSummary {
    delete data.noTimesheets;
    delete data.noBrassing;
    delete data.canApprove;
    delete data.isApproved;
    delete data.siteHours;
    delete data.duration;
    delete data.hasInvalidBrasses;

    return data;
  }

  protected mapToObject(responseData: any): EmployeeSummary {
    return responseData as EmployeeSummary;
  }

}
