import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CompletionStatus, CompletionStatusService} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class CompletionStatusTypeGridService extends TableService<CompletionStatus> {
  constructor(http: HttpClient, private clientService: CompletionStatusService) {
    super(http, 'CompletionStatus', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | CompletionStatus): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  delete(data: any | CompletionStatus): Observable<any> {
    return this.clientService.completionStatusPatch(data.id, {name: data.name, deletionDate: new Date()});
  }

  update(data: any | CompletionStatus): Observable<any> {
    return this.clientService.completionStatusPatch(data.id, this.cleanupItem(data));
  }

  add(data: any | CompletionStatus): Observable<any> {
    return this.clientService.completionStatusPost(this.cleanupItem(data));
  }

  protected mapToObject(responseData: any): CompletionStatus {
    return responseData as CompletionStatus;
  }
}
