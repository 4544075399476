import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AsCutProgressService } from './api/asCutProgress.service';
import { AssemblyService } from './api/assembly.service';
import { AttendanceCodeService } from './api/attendanceCode.service';
import { CompletionStatusService } from './api/completionStatus.service';
import { ComponentService } from './api/component.service';
import { ContractService } from './api/contract.service';
import { ContractExtraTimeService } from './api/contractExtraTime.service';
import { CrewService } from './api/crew.service';
import { CrewDetailService } from './api/crewDetail.service';
import { DailyTimesheetHeaderService } from './api/dailyTimesheetHeader.service';
import { DailyTimesheetSummaryService } from './api/dailyTimesheetSummary.service';
import { EmployeeService } from './api/employee.service';
import { EmployeeResponseService } from './api/employeeResponse.service';
import { EnvironmentService } from './api/environment.service';
import { ExtraTimeRuleService } from './api/extraTimeRule.service';
import { ExtraWorkOrderService } from './api/extraWorkOrder.service';
import { InventoryItemService } from './api/inventoryItem.service';
import { LatestProductNoteService } from './api/latestProductNote.service';
import { LatestProductTimesheetService } from './api/latestProductTimesheet.service';
import { LatestProductTimesheetCompletionStatusPerCompletionStatusService } from './api/latestProductTimesheetCompletionStatusPerCompletionStatus.service';
import { LatestProductTimesheetPerTaskTypeService } from './api/latestProductTimesheetPerTaskType.service';
import { MarkStatusService } from './api/markStatus.service';
import { MeetingService } from './api/meeting.service';
import { MeetingDetailService } from './api/meetingDetail.service';
import { MetadataService } from './api/metadata.service';
import { ProductService } from './api/product.service';
import { ProductLocationService } from './api/productLocation.service';
import { ProductNoteService } from './api/productNote.service';
import { ProductTimesheetService } from './api/productTimesheet.service';
import { ProductTimesheetAndNotesService } from './api/productTimesheetAndNotes.service';
import { ProductTimesheetCompletionStatusService } from './api/productTimesheetCompletionStatus.service';
import { QuestionService } from './api/question.service';
import { ReportService } from './api/report.service';
import { SampleDataService } from './api/sampleData.service';
import { ShippingService } from './api/shipping.service';
import { ShippingDetailService } from './api/shippingDetail.service';
import { StationService } from './api/station.service';
import { StationGroupService } from './api/stationGroup.service';
import { StationLocationService } from './api/stationLocation.service';
import { StationProductService } from './api/stationProduct.service';
import { StemsWorkstationService } from './api/stemsWorkstation.service';
import { SystemOptionService } from './api/systemOption.service';
import { TaskTypeService } from './api/taskType.service';
import { TaskTypeGroupService } from './api/taskTypeGroup.service';
import { TimeCardService } from './api/timeCard.service';
import { TimesheetService } from './api/timesheet.service';
import { TradeUnionService } from './api/tradeUnion.service';
import { TransmittalService } from './api/transmittal.service';
import { WorkAreaService } from './api/workArea.service';
import { WorkShiftService } from './api/workShift.service';
import { WorkTradeService } from './api/workTrade.service';
import { WorkTradeUnionService } from './api/workTradeUnion.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
