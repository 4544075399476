/**
 * Production Progressing API
 * An API for production progressing and shop time tracking.
 *
 * The version of the OpenAPI document: v1
 * Contact: david@framewerx.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RuleType = 'Standard' | 'FlexTime';

export const RuleType = {
    Standard: 'Standard' as RuleType,
    FlexTime: 'FlexTime' as RuleType
};

