import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Contract} from '../../../production-progressing-api';
import {IContractLoad} from '../../pages/shipping/models/contract-load';

@Injectable()
export class ActiveContractLoadService implements OnDestroy {

  static readonly StorageKey = 'activeContractLoad';

  protected activeContractLoad: BehaviorSubject<IContractLoad> = new BehaviorSubject(null);
  protected destroy$ = new Subject<boolean>();

  constructor() {
    if (sessionStorage.getItem(ActiveContractLoadService.StorageKey)) {
      const item: IContractLoad = JSON.parse(sessionStorage.getItem(ActiveContractLoadService.StorageKey));
      this.activeContractLoad.next(item);
      this.refreshCachedTimesheet(item);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  refreshCachedTimesheet(item: IContractLoad = null) {
    if (item != null && item.contract != null) {
      this.getContractLoad(item.contract, item.loadId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((e) => this.activeContractLoad.next(e));
    }
  }

  getContractLoad(contract: Contract, loadId: number): Observable<IContractLoad> {
    const item: IContractLoad = {
      contract,
      loadId,
    };
    return of(item);
  }

  onActiveContractLoadChange(): Observable<IContractLoad> {
    return this.activeContractLoad;
  }

  publishTimesheet(item: IContractLoad) {
    this.activeContractLoad.next(item);
    sessionStorage.setItem(ActiveContractLoadService.StorageKey, JSON.stringify(item));
  }
}
