import {Component, Input, isDevMode, OnDestroy, OnInit} from '@angular/core';

import {NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {UserService} from '../../../@core/data/users.service';
import {AnalyticsService} from '../../../@core/utils/analytics.service';
import {LOGOUT_TITLE} from '../../../pages/pages-menu';
import {map, switchMap, take, takeUntil} from 'rxjs/operators';
import {EnvironmentService} from '../../../../production-progressing-api';
import {NbAccessChecker} from '@nebular/security';
import {LayoutService} from '../../../@core/utils/layout.service';
import {ErrorService} from '../../../pages/shared/errors/error.service';
import {MeasurementTypeService} from '@framewerx/kendo-angular-grid';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() position = 'normal';

  private destroy$ = new Subject<void>();
  userPictureOnly: boolean = false;

  user: any;
  isViewAndProfileGranted: boolean = false;

  envProfile = environment.environment;

  isDevMode = isDevMode();
  location = '';

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'corporate';

  userMenu: NbMenuItem[] = [
    {title: 'Profile', url: 'https://portal.office.com/account/#personalinfo', target: '_blank'},
    {title: LOGOUT_TITLE},
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private environmentService: EnvironmentService,
    private userService: UserService,
    public accessChecker: NbAccessChecker,
    private analyticsService: AnalyticsService,
    public measurementService: MeasurementTypeService,
    public errorService: ErrorService) {
  }

  ngOnInit() {
    this.userService.onUserChange().pipe(
      takeUntil(this.destroy$),
      switchMap((user: any) => {
        this.user = user;
        return this.accessChecker
          .isGranted('view', 'profile')
          .pipe(take(1), takeUntil(this.destroy$));
      })
    ).subscribe(e => {
      this.isViewAndProfileGranted = e;
    });

    this.environmentService.environmentGet()
      .pipe(takeUntil(this.destroy$))
      .subscribe((e) => {
        this.location = e.location;
      });

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        takeUntil(this.destroy$),
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        takeUntil(this.destroy$),
        map(({name}) => name),
      )
      .subscribe(themeName => this.currentTheme = themeName);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
    return false;
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  displayErrorAlerts() {
    this.errorService.notificationButtonClicked = true;
    this.errorService.displayAlerts();
  }

  toggleMeasurement() {
    this.measurementService.toggleLengthMeasure();
  }

}
