/**
 * Production Progressing API
 * An API for production progressing and shop time tracking.
 *
 * The version of the OpenAPI document: v1
 * Contact: david@framewerx.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Progress = 'NotStarted' | 'WorkedOn' | 'Done';

export const Progress = {
    NotStarted: 'NotStarted' as Progress,
    WorkedOn: 'WorkedOn' as Progress,
    Done: 'Done' as Progress
};

