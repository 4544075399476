import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Product } from '../../../production-progressing-api';

/**
 * Contains a basket of products that a worker is about to progress at the same time.
 */
@Injectable()
export class ProductBasketService {

  private static readonly StorageKey = 'productBasket';

  private static SaveToStorage(products: null | Product[]) {
    sessionStorage.setItem(ProductBasketService.StorageKey, JSON.stringify(products));
  }

  protected productsInBasket: BehaviorSubject<Product[]> = new BehaviorSubject([]);

  constructor() {
    if (sessionStorage.getItem(ProductBasketService.StorageKey)) {
      const products: Product[] = JSON.parse(sessionStorage.getItem(ProductBasketService.StorageKey));
      this.productsInBasket.next(products);
    }
  }

  onProductsInBasketChange(): Observable<Product[]> {
    return this.productsInBasket;
  }

  getProductsInBasket(): Product[] {
    return this.productsInBasket.getValue();
  }

  addProduct(product: Product) {
    const currentProducts = this.productsInBasket.value;
    const existingIndex = currentProducts.findIndex((x) => x.id === product.id);
    if (existingIndex === -1) {
      const updatedProducts = [product, ...currentProducts];
      this.productsInBasket.next(updatedProducts);
      ProductBasketService.SaveToStorage(updatedProducts);
    }
  }

  clearProductsFromBasket() {
    this.productsInBasket.next([]);
    ProductBasketService.SaveToStorage([]);
  }

  removeProduct(product: Product) {
    const currentProducts = this.productsInBasket.value.filter((obj) => obj !== product);
    this.productsInBasket.next(currentProducts);
    ProductBasketService.SaveToStorage(currentProducts);
  }
}
