import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {TimeCard, TimeCardService,} from '../../../../production-progressing-api';
import {TableService} from '@framewerx/kendo-angular-grid';

@Injectable()
export class TimecardGridService extends TableService<TimeCard> {
  constructor(http: HttpClient, private clientService: TimeCardService) {
    super(http, 'TimeCard', '/api/v1/odata/');
  }

  getIdUrlPart(data: any | TimeCard): string {
    if (data == null) { return ''; }
    return `/${data.id}`;
  }

  delete(data: any | TimeCard): Observable<any> {
    // TODO
    return this.clientService.timeCardPatch(data.id, {deletionDate: new Date()});
    // return EMPTY;
  }

  update(data: any | TimeCard): Observable<any> {
    return EMPTY;
  }

  add(data: any | TimeCard): Observable<any> {
    return EMPTY;
  }

  protected mapToObject(responseData: any): TimeCard {
    return responseData as TimeCard;
  }
}
