import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NbAlertModule, NbIconModule} from '@nebular/theme';
import {DatePickerModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {ExcelModule, GridModule, PDFModule} from '@progress/kendo-angular-grid';
import {NumericTextBoxModule, SliderModule} from '@progress/kendo-angular-inputs';
import {IntlModule} from '@progress/kendo-angular-intl';
import {SchedulerModule} from '@progress/kendo-angular-scheduler';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../../../environments/environment';
import {ThemeModule} from '../../@theme/theme.module';
import {ApplicationPipesModule} from '../../pipes/application-pipes.module';
import {AdminHeaderContainerComponent} from './admin-header-container/admin-header-container.component';
import {BackComponent} from './back/back.component';
import {EmployeeWorkDialogComponent} from './dialog/employee-work-dialog/employee-work-dialog.component';
import {
  ProductWorkHistoryDialogComponent
} from './dialog/product-work-history-dialog/product-work-history-dialog.component';
import {DropDownListFilterComponent} from './drop-down-list-filter/drop-down-list-filter.component';
import {DropDownListMenuFilterComponent} from './drop-down-list-filter/drop-down-list-menu-filter.component';
import {ViewErrorsComponent} from './errors/view-errors/view-errors.component';
import {ProductDetailHeaderComponent} from './product-detail-header/product-detail-header.component';
import {ProductTimesheetHistoryTableComponent} from './product-table/product-timesheet-history-table.component';
import {ProductWorkHistoryTableComponent} from './product-work-history-table/product-work-history-table.component';
import {SingleQuestionFormComponent} from './questions/single-question-form/single-question-form.component';
import {ContractSelectorComponent} from './selectors/contract-selector/contract-selector.component';
import {EwoSelectorComponent} from './selectors/ewo-selector/ewo-selector.component';
import {
  ProductLocationSelectorComponent
} from './selectors/product-location-selector/product-location-selector.component';
import {ShipLoadSelectorComponent} from './selectors/ship-load-selector/ship-load-selector.component';
import {TaskTypeSelectorComponent} from './selectors/task-type-selector/task-type-selector.component';
import {SimpleBrassTableComponent} from './simple-brass-table/simple-brass-table.component';
import {SimpleRouterOutletComponent} from './simple-router-outlet/simple-router-outlet.component';
import {SimpleWorkshiftTableComponent} from './simple-workshift-table/simple-workshift-table.component';
import {SubTaskSelectComponent} from './sub-task-select/sub-task-select.component';
import {TimeWrapperComponent} from './time-wrapper/time-wrapper.component';
import {KendoAngularGridConfig, KendoAngularGridModule} from '@framewerx/kendo-angular-grid';
import {ShippingTableComponent} from './tables/shipping-table/shipping-table.component';
import {ComponentTableComponent} from './tables/component-table/component-table.component';
import {ProductTableComponent} from './tables/product-table/product-table.component';
import {LatestProductTimesheetTableComponent} from './tables/product-table/latest-product-timesheet-table.component';
import {FabricationProductTableComponent} from './tables/product-table/fabrication/fabrication-product-table.component';
import {QualityProductTableComponent} from './tables/product-table/quality/quality-product-table.component';
import {GroupSelectorComponent} from './selectors/group-selector/group-selector.component';
import {BaseTaskSelectorComponent} from './selectors/base-task-selector/base-task-selector.component';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {CutSelectorComponent} from './selectors/cut-selector/cut-selector.component';
import {MassEditorComponent} from './mass-editor/mass-editor.component';
import {AscutDetailTableComponent} from './ascut-detail-table/ascut-detail-table.component';
import {LoadDrawingsComponent} from './drawings/load-drawings/load-drawings.component';
import {NgIf} from "@angular/common";
import {DrawingAdminContainerComponent} from './drawings/drawing-admin-container/drawing-admin-container.component';
import {DrawingAdminMenuComponent} from './drawings/drawing-admin-menu/drawing-admin-menu.component';
import {DrawingByContractComponent} from './drawings/drawing-by-contract/drawing-by-contract.component';
import {DrawingPreviewComponent} from './drawings/drawing-preview/drawing-preview.component';
import {PdfJsViewerModule} from "ng2-pdfjs-viewer";
import {DrawingDialogComponent} from './drawings/drawing-dialog/drawing-dialog.component';
import {EditorDialogComponent} from './drawings/editor-dialog/editor-dialog.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {OpenInNewTabButtonComponent} from './drawings/open-in-new-tab-button/open-in-new-tab-button.component';
import {DrawingByGroupComponent} from './drawings/drawing-by-group/drawing-by-group.component';

export function kendoGridConfigFactory(): KendoAngularGridConfig {
  return {apiRootUrl: `${environment.apiBaseUrl}`} as KendoAngularGridConfig;
}

@NgModule({
  imports: [
    LoggerModule.forRoot({
      // serverLoggingUrl: '/api/logs', // TODO: Implement logging in server https://stackoverflow.com/a/53078568/2557554
      level: environment.loggingLevel,
      // serverLogLevel: NgxLoggerLevel.ERROR,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    ThemeModule,
    GridModule,
    ExcelModule,
    PDFModule,
    SliderModule,
    DialogModule,
    DatePickerModule,
    TimePickerModule,
    IntlModule,
    ApplicationPipesModule,
    NbAlertModule,
    SchedulerModule,
    DropDownListModule,
    RouterModule,
    NumericTextBoxModule,
    KendoAngularGridModule,
    ButtonModule,
    NbIconModule,
    NgIf,
    PdfJsViewerModule,
    EditorDialogComponent,
    NgxExtendedPdfViewerModule
  ],
  declarations: [
    AscutDetailTableComponent,
    ViewErrorsComponent,
    BackComponent,
    ContractSelectorComponent,
    TimeWrapperComponent,
    SimpleWorkshiftTableComponent,
    SimpleBrassTableComponent,
    SubTaskSelectComponent,
    AdminHeaderContainerComponent,
    DropDownListFilterComponent,
    DropDownListMenuFilterComponent,
    EmployeeWorkDialogComponent,
    ProductLocationSelectorComponent,
    SingleQuestionFormComponent,
    ProductWorkHistoryDialogComponent,
    ProductTimesheetHistoryTableComponent,
    EwoSelectorComponent,
    ShipLoadSelectorComponent,
    TaskTypeSelectorComponent,
    ProductDetailHeaderComponent,
    ProductWorkHistoryTableComponent,
    SimpleRouterOutletComponent,
    ShippingTableComponent,
    ComponentTableComponent,
    ProductTableComponent,
    LatestProductTimesheetTableComponent,
    FabricationProductTableComponent,
    QualityProductTableComponent,
    GroupSelectorComponent,
    BaseTaskSelectorComponent,
    CutSelectorComponent,
    MassEditorComponent,
    LoadDrawingsComponent,
    DrawingAdminContainerComponent,
    DrawingAdminMenuComponent,
    DrawingByContractComponent,
    DrawingPreviewComponent,
    DrawingDialogComponent,
    OpenInNewTabButtonComponent,
    DrawingByGroupComponent,
  ],
  providers: [],
  exports: [
    AscutDetailTableComponent,
    BaseTaskSelectorComponent,
    GroupSelectorComponent,
    ViewErrorsComponent,
    BackComponent,
    ContractSelectorComponent,
    TimeWrapperComponent,
    SimpleWorkshiftTableComponent,
    SimpleBrassTableComponent,
    SubTaskSelectComponent,
    AdminHeaderContainerComponent,
    DropDownListFilterComponent,
    DropDownListMenuFilterComponent,
    EmployeeWorkDialogComponent,
    ProductLocationSelectorComponent,
    SingleQuestionFormComponent,
    ProductWorkHistoryDialogComponent,
    ProductTimesheetHistoryTableComponent,
    ShipLoadSelectorComponent,
    EwoSelectorComponent,
    TaskTypeSelectorComponent,
    ProductDetailHeaderComponent,
    ProductWorkHistoryTableComponent,
    SimpleRouterOutletComponent,
    ShippingTableComponent,
    ComponentTableComponent,
    ProductTableComponent,
    LatestProductTimesheetTableComponent,
    FabricationProductTableComponent,
    QualityProductTableComponent,
    CutSelectorComponent,
    MassEditorComponent,
    DrawingPreviewComponent,
    DrawingDialogComponent,
  ],
})
export class SharedModule {
}
