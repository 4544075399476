export let DECODER_CONFIG;
DECODER_CONFIG = {
  inputStream: {
    size: 640,
  },
  locator: {
    patchSize: 'small',
    halfSample: true,
  },
  numOfWorkers: 2,
  decoder: {
    // readers: ['ean_reader', 'code_128_reader', 'ean_8_reader', 'code_39_reader', 'code_39_vin_reader',
    //   'codabar_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader'],
    readers: ['code_39_reader'], // To have a better performance, let's just pass only one type of barcode
  },
  locate: true,
  src: null,
};

export let DECODER_LIVE_CONFIG;
DECODER_LIVE_CONFIG = {
  locator: {
    patchSize: 'large',
    halfSample: true,
  },
  numOfWorkers: 4,
  decoder: {
    // readers: ['ean_reader', 'code_128_reader', 'ean_8_reader', 'code_39_reader', 'code_39_vin_reader',
    //   'codabar_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader'],
    readers: ['code_39_reader'], // To have a better performance, let's just pass only one type of barcode
  },
  locate: true,
  // frequency: 15,
};
